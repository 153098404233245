import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import Content from '../Primitive/Content'

class OfferTerms extends PureComponent {
  render () {
    const { terms, theme, termsLink, gutterless, noWidth, seasonal } = this.props

    const termsContent = `${terms}${termsLink ? ' <a href=' + termsLink + '>View Full Terms and Conditions</a>' : ''}`

    return (
      <div className={classNames(
        'OfferTerms',
        theme && `OfferTerms-${theme}`,
        seasonal && 'OfferTerms--seasonal'
      )}>
        <Content center gutter={!gutterless} width={theme === 'aus' && !noWidth ? 'small' : undefined}>
          <div className="OfferTermsHeading">Terms and Conditions</div>
          <div className="OfferTermsContent" dangerouslySetInnerHTML={{ __html: termsContent }} />
        </Content>
      </div>
    )
  }
}

OfferTerms.propTypes = {
  terms: PropTypes.string,
  theme: PropTypes.string,
  termsLink: PropTypes.string,
  gutterless: PropTypes.bool,
  noWidth: PropTypes.bool,
  seasonal: PropTypes.bool
}

export default withTheme(OfferTerms)
