import React, { PureComponent } from 'react'
import { oneOf, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../../../Theme/withTheme'

import NativeSelectControl from '../NativeSelectControl'

export class CustomSelectControl extends PureComponent {
  render () {
    const { status, theme } = this.props
    const componentClassName = classNames(
      'CustomSelectControl',
      theme && `CustomSelectControl-${theme}`,
      status && status
    )

    return (
      <span className={componentClassName}>
        <NativeSelectControl {...this.props} />
      </span>
    )
  }
}

CustomSelectControl.displayName = 'CustomSelectControl'

CustomSelectControl.propTypes = {
  status: oneOf([ 'error', 'notice', 'success', 'warning' ]),
  theme: string
}

export default withTheme(CustomSelectControl)
