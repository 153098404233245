import React from 'react'
import ReactDOM from 'react-dom'
import getOffers from './offers-getter'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'
import SortableOffersComponent from './SortableOffersComponent'

import 'whatwg-fetch'
const debug = require('debug')('sortable offers')

export default (serviceLocator) => {
  const initialise = async (appNode, sortType) => {
    const { dataset } = appNode
    sortType = sortType || dataset.sort
    const data = { ...dataset }
    data.limit = Number.parseInt(appNode.dataset.limit)
    const result = await getOffers(serviceLocator.instancePath, data, sortType)
    const theme = document.querySelector('body').dataset.theme

    ReactDOM.render(
      <ThemeContext.Provider value={theme}>
        <SortableOffersComponent base={serviceLocator.instancePath} originalOffers={result} {...data} />
      </ThemeContext.Provider>,
      appNode
    )
  }

  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-sortable-widget').forEach(initialise)
  })
}
