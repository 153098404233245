import React, { PureComponent } from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'

class Prose extends PureComponent {
  render () {
    const { children, className, html, theme } = this.props
    if (!children && !html) return null
    return (
      <div
        className={classNames(
          'Prose',
          theme && `Prose-${theme}`,
          className
        )}
        {...html && { dangerouslySetInnerHTML: { __html: html } }}
      >
        {children}
      </div>
    )
  }
}

Prose.displayName = 'Prose'

Prose.propTypes = {
  children: node,
  className: string,
  html: string,
  inverse: bool,
  theme: string
}

export default withTheme(Prose)
