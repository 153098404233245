import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import Prose from '../Primitive/Prose'
import Button from '../Primitive/Button'

class NotLoggedInButtonArea extends PureComponent {
  render () {
    const { loginOnClick, subscribeOnClick, block } = this.props
    return (
      <Fragment>
        <Button block={block} className="RedemptionButtonCTA" login uppercase onClick={loginOnClick}>Log in</Button>
        <Prose className="RedemptionButtonFeedback">
          <p>Not a Member? <a href="#" onClick={subscribeOnClick}>Subscribe now</a></p>
        </Prose>
      </Fragment>
    )
  }
}

NotLoggedInButtonArea.propTypes = {
  loginOnClick: PropTypes.func,
  subscribeOnClick: PropTypes.func,
  block: PropTypes.bool
}

export default NotLoggedInButtonArea
