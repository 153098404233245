import { createValidatorAllowingFailureMessageOverride } from 'validity'

export default (parentId, basePath) => createValidatorAllowingFailureMessageOverride(async (propertyName, object, callback) => {
  const val = object[propertyName]

  const apiUrl = `${basePath}api/code-word/${parentId}?word=${encodeURIComponent(val)}`

  const request = await fetch(apiUrl, { credentials: 'include' })
  const result = await request.json()

  callback(null, result.valid)
}, `This code word is currently invalid`)
