import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'

import Field, {
  FieldQuestion,
  FieldAnswer,
  FieldRequired,
  FieldAssistance,
  FieldFeedback
} from '../../Field'
import VisuallyHidden from '../../VisuallyHidden'

class TextFieldTemplate extends PureComponent {
  render () {
    const {
      assistance,
      children,
      controlName,
      feedback,
      hideLabel,
      inverse,
      label,
      required,
      status
    } = this.props

    let feedbackStr = feedback

    const TextFieldTemplateQuestion = () => (
      <FieldQuestion htmlFor={controlName} /* matches Control controlName */>
        {label && <span dangerouslySetInnerHTML={{ __html: label }} />}
        {required && <FieldRequired />}
        {/* matches Control required attribute */}
      </FieldQuestion>
    )

    return (
      <Field id={`field--${controlName}`} status={status} template="text">
        {hideLabel ? (
          <VisuallyHidden>
            <TextFieldTemplateQuestion />
          </VisuallyHidden>
        ) : (
          <TextFieldTemplateQuestion />
        )}
        <FieldAnswer>{children}</FieldAnswer>
        {feedbackStr && (
          <FieldFeedback inverse={inverse}>{feedbackStr}</FieldFeedback>
        )}
        {assistance && <FieldAssistance>{assistance}</FieldAssistance>}
      </Field>
    )
  }
}

TextFieldTemplate.propTypes = {
  assistance: node,
  controlName: string,
  children: node.isRequired,
  feedback: node,
  hideLabel: bool,
  inverse: bool,
  label: node.isRequired,
  required: bool,
  status: oneOf([ 'error', 'notice', 'success', 'warning' ])
}

export default TextFieldTemplate
