import React, { PureComponent } from 'react'
import { bool, string } from 'prop-types'

class Option extends PureComponent {
  render () {
    const { children, disabled, value } = this.props

    return (
      <option value={value || children} disabled={disabled}>
        {children}
      </option>
    )
  }
}

Option.displayName = 'Option'

Option.propTypes = {
  children: string.isRequired,
  disabled: bool,
  value: string
}

export default Option
