import React, { PureComponent } from 'react'
import {
  arrayOf,
  bool,
  func,
  node,
  number,
  oneOf,
  array,
  oneOfType,
  string
} from 'prop-types'
import classnames from 'classnames'

import withTheme from '../../../../Theme/withTheme'
import Option from '../Option'

export class NativeSelectControl extends PureComponent {
  render () {
    const {
      autofocus,
      options,
      children,
      className,
      defaultValue,
      disabled,
      id,
      multiple,
      name,
      onBlur,
      onChange,
      onFocus,
      required,
      size,
      status,
      tabindex,
      theme,
      value
    } = this.props

    const componentClassName = classnames(
      'NativeSelectControl',
      theme && `NativeSelectControl-${theme}`,
      multiple && 'multiline',
      status && `${status}`,
      className
    )

    return (
      <select
        autoFocus={autofocus}
        defaultValue={defaultValue}
        className={componentClassName}
        disabled={disabled}
        id={id}
        multiple={multiple}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        onFocus={onFocus}
        required={required}
        size={size}
        status={status}
        tabIndex={tabindex}
        value={value}
      >
        {options && options.length > 0 && options.map((option, i) => <Option key={`option${i}`} value={option.value}>{option.text}</Option>)}
        {!options && children}
      </select>
    )
  }
}

NativeSelectControl.propTypes = {
  autofocus: bool,
  children: node,
  className: string,
  defaultValue: string,
  disabled: bool,
  id: string,
  multiple: bool,
  name: string.isRequired,
  onBlur: func,
  onChange: func,
  onFocus: func,
  required: bool,
  size: number,
  status: oneOf([ 'error', 'notice', 'success', 'warning' ]),
  tabindex: number,
  theme: string,
  options: array,
  value: oneOfType([ arrayOf(string), string ])
}

export default withTheme(NativeSelectControl)
