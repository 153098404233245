const determineWidthStyle = (width, totalOffers) => {
  const widthToClass = {
    One: 'tablet-landscape-one-whole',
    Two: 'tablet-landscape-one-half',
    Three: 'tablet-landscape-one-third',
    OneThree: 'tablet-landscape-one-third'
  }
  if (width !== 'Auto') {
    return widthToClass[width]
  }
  if (totalOffers === 1) {
    return widthToClass['One']
  } else if (totalOffers === 2 || totalOffers === 4) {
    return widthToClass['Two']
  } else {
    return widthToClass['Three' || 'OneThree']
  }
}

export default determineWidthStyle
