import React from 'react'
import PropTypes from 'prop-types'
import TextControl from '../TextControl'
import classNames from 'classnames'
import withTheme from '../../../Theme/withTheme'

const hasWindow = typeof window !== 'undefined'
let Pikaday = hasWindow ? require('pikaday') : {}

class DateControl extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      readOnly: true
    }
  }

  componentDidMount () {
    if (!hasWindow) return false
    const { onChange, name } = this.props
    this.picker = new Pikaday({
      field: this.input,
      yearRange: [ 1920, new Date().getFullYear() ],
      onSelect () {
        this.setState({ readOnly: false })
        onChange({
          currentTarget: {
            name: name,
            value: this.getMoment().format('DD MMMM YYYY')
          },
          target: {}
        })
      }
    })
  }

  render () {
    const { className, theme } = this.props
    return (
      <TextControl
        {...this.props}
        className={classNames(
          className,
          'DateControl',
          theme && `DateControl-${theme}`
        )}
        element="input"
        innerRef={input => {
          this.input = input
        }}
        readOnly={this.state.readOnly}
        placeholder={this.props.placeholder}
      />
    )
  }
}

DateControl.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  className: PropTypes.string,
  placeholder: PropTypes.string,
  theme: PropTypes.string
}

export default withTheme(DateControl)
