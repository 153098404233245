import React from 'react'
import ReactDOM from 'react-dom'
import BasicRedemptionArea from './BasicRedemptionArea'
import UmbrellaChildComponent from './UmbrellaChildComponent'
import PurchaseRedemptionArea from './PurchaseRedemptionArea'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'
import createSchema from '../form-builder/schema'

export default (serviceLocator) => {
  const initialise = (appNode, extras) => {
    const theme = document.querySelector('body').dataset.theme
    const offerId = appNode.dataset.offerId
    const redemptionType = appNode.dataset.redemptionType
    const redemptionCtaText = appNode.dataset.redemptionCtaText
    const redemptionInfoLoaded = appNode.dataset.redemptionInfoLoaded === 'true'
    const showMobileWallet = appNode.dataset.showMobileWallet === 'true'
    const paymentSuccess = appNode.dataset.paymentSuccess === 'true'
    const submissionSuccess = appNode.dataset.submissionSuccess === 'true'
    const hasGutter = appNode.dataset.hasGutter === 'true'
    const formData = appNode.dataset.formData ? JSON.parse(appNode.dataset.formData) : null
    const prePopulateData = appNode.dataset.prePopulateData ? JSON.parse(appNode.dataset.prePopulateData) : null
    const thankYouData = appNode.dataset.thankYouData ? JSON.parse(appNode.dataset.thankYouData) : null
    const redemptionTypeProperties = appNode.dataset.redemptionTypeProperties ? JSON.parse(appNode.dataset.redemptionTypeProperties) : null
    const schema = formData ? createSchema(formData.questions, offerId, serviceLocator.instancePath) : null
    const hasPastPurchase = appNode.dataset.hasPastPurchase === 'true'
    const isDataCapture = redemptionType === 'data-capture'

    let Component = BasicRedemptionArea
    const genericProps = {
      instancePath: serviceLocator.instancePath,
      formData,
      schema,
      config: serviceLocator.config,
      prePopulateData,
      thankYouData,
      offerId,
      showMobileWallet,
      redemptionCtaText
    }
    let props = null

    if (extras) {
      Component = UmbrellaChildComponent
      props = {
        ...genericProps,
        ...extras,
        submissionSuccess,
        paymentSuccess,
        redemptionTypeProperties,
        redemptionType,
        dataCapture: isDataCapture,
        redemptionInfoLoaded
      }
    } else if (redemptionType === 'purchase') {
      Component = PurchaseRedemptionArea
      props = { ...genericProps, submissionSuccess, paymentSuccess, redemptionTypeProperties, hasPastPurchase }
    } else {
      props = { ...genericProps, hasGutter, dataCapture: isDataCapture, redemptionInfoLoaded }
    }

    ReactDOM.render(
      <ThemeContext.Provider value={theme}>
        <Component {...props} />
      </ThemeContext.Provider>,
      appNode
    )
  }

  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-redemption-area').forEach(initialise)
    document.querySelectorAll('.js-umbrella-child-offer').forEach(appNode => {
      const offer = JSON.parse(appNode.dataset.offer || '{}')
      const timezone = appNode.dataset.timezone || ''
      const state = appNode.dataset.redemptionState || ''

      if (offer.openingDate) {
        offer.openingDate = new Date(offer.openingDate)
      }

      initialise(appNode, { offer, timezone, state })
    })
  })
}
