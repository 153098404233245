import React from 'react'
import PropTypes from 'prop-types'
import withTheme from '../../Theme/withTheme'
import TextWithImageCtaRewards from './TextWithImageCtaRewards'
import TextWithImageCtaAus from './TextWithImageCtaAus'

class TextWithImageCta extends React.Component {
  render () {
    const { theme } = this.props
    const Component = {
      'rewards': <TextWithImageCtaRewards {...this.props} />,
      'aus': <TextWithImageCtaAus {...this.props} />
    }
    return Component[theme]
  }
}

TextWithImageCta.propTypes = {
  theme: PropTypes.string
}

export default withTheme(TextWithImageCta)
