import React, { PureComponent } from 'react'
import { string } from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'

class OffsetAnchor extends PureComponent {
  render () {
    const { theme, identifier } = this.props

    return (
      <div
        id={identifier}
        className={classNames(
          'OffsetAnchor',
          theme && `OffsetAnchor-${theme}`
        )}
      />
    )
  }
}

OffsetAnchor.displayName = 'OffsetAnchor'

OffsetAnchor.propTypes = {
  theme: string,
  identifier: string
}

export default withTheme(OffsetAnchor)
