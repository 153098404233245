import React from 'react'
import ReactDOM from 'react-dom'
import Expander from '../../../site/layout/component/Primitive/Expander'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'

const debug = require('debug')('summaryWrapper')

export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const theme = document.querySelector('body').dataset.theme

    document.querySelectorAll('.js-expander').forEach(appNode => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      ReactDOM.render(
        <ThemeContext.Provider value={theme}>
          <Expander {...props} />
        </ThemeContext.Provider>,
        appNode
      )
    })
  })
}
