import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { Grid } from '../Grid'
import { widthMin } from '../Media'
import withTheme from '../../Theme/withTheme'

class Carousel extends PureComponent {
  componentDidMount () {
    // this is here because we need to have the window context before using Flickity
    const Flickity = require('flickity')
    require('flickity-imagesloaded')

    if (!this.carousel) return

    const initCarousel = () => {
      this.flickity = new Flickity(this.carousel, {
        wrapAround: true,
        autoPlay: this.props.autoplay && 5000,
        pageDots: true,
        imagesLoaded: true,
        arrowShape: {
          x0: 30,
          x1: 60,
          y1: 50,
          x2: 65,
          y2: 45,
          x3: 40
        }
      })

      if (this.props.childRef) {
        this.props.childRef.flickity = this.flickity
      }
    }

    const destroyCarousel = () => {
      if (this.flickity) this.flickity.destroy()
    }

    const handleCarousel = breakpoint => {
      if (breakpoint.matches) {
        destroyCarousel()
      } else {
        initCarousel()
      }
    }

    if (this.props.breakpoint) {
      const breakpoint = window.matchMedia(widthMin(this.props.breakpoint))
      const query = e => handleCarousel(e)
      this.props.breakpoint && breakpoint.addListener(query)
      handleCarousel(breakpoint)
    } else {
      initCarousel()
    }
  }

  componentWillUnmount () {
    if (this.flickity) this.flickity.destroy()
  }

  render () {
    const { children, wrapper, theme } = this.props
    if (wrapper) {
      return (
        <Grid flexTabletLandscape
          className={`Carousel-${theme}`}
          refNode={(carousel) => { this.carousel = carousel }}
        >
          {children}
        </Grid>
      )
    } else {
      return (
        <div
          className={`Carousel-${theme}`}
          ref={(carousel) => { this.carousel = carousel }}
        >
          {children}
        </div>
      )
    }
  }
}

Carousel.propTypes = {
  children: PropTypes.node,
  wrapper: PropTypes.bool,
  breakpoint: PropTypes.string,
  theme: PropTypes.string,
  autoplay: PropTypes.bool,
  childRef: PropTypes.object
}

export default withTheme(Carousel)
