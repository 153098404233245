import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Spring, animated } from 'react-spring'
import withTheme from '../../Theme/withTheme'
import Icon from '../Icon'
import Content from '../Content'

const defaultTheme = 'aus'
const themedIcons = {
  expanded: {
    rewards: 'ChevronUp',
    aus: 'Minus'
  },
  collapsed: {
    rewards: 'ChevronDown',
    aus: 'Plus'
  }
}

const getToggleIcon = (theme, expanded) => {
  const iconSet = expanded ? themedIcons['expanded'] : themedIcons['collapsed']
  return iconSet[theme] || iconSet[defaultTheme]
}

class Expander extends PureComponent {
  constructor () {
    super()

    this.state = {
      expanded: false
    }

    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle () {
    this.setState({ expanded: !this.state.expanded })
  }

  render () {
    const { theme, title, content } = this.props

    const ExpanderToggle = () => theme === 'rewards' ? (
      <Spring
        native
        from={{ paddingTop: 15 }}
        to={{ paddingTop: 15 }}
      >
        {props => (
          <animated.button style={props} className="ExpanderToggle" onClick={this.handleToggle}>
            <h3 className="ExpanderTitle">{title}</h3>
            <Icon className="ExpanderToggleIcon" type={getToggleIcon(theme, this.state.expanded)} />
          </animated.button>
        )}
      </Spring>
    ) : (
      <button className="ExpanderToggle" onClick={this.handleToggle}>
        <div className="ExpanderTitle">{title}</div>
        <Icon className="ExpanderToggleIcon" type={getToggleIcon(theme, this.state.expanded)} />
      </button>
    )

    return (
      <Content center gutter>
        <div
          className={classNames(
            'Expander',
            theme && `Expander-${theme}`,
            this.state.expanded && 'expanded'
          )}
        >
          <ExpanderToggle />
          <Spring
            native
            from={{ height: 0 }}
            to={{ height: this.state.expanded ? 'auto' : 0 }}
          >
            {props => (
              <animated.div className="ExpanderContentWrapper" style={props}>
                <div className="ExpanderContent" dangerouslySetInnerHTML={{ __html: content }} />
              </animated.div>
            )}
          </Spring>
        </div>
      </Content>
    )
  }
}

Expander.displayName = 'Expander'

Expander.propTypes = {
  content: PropTypes.string,
  title: PropTypes.string,
  theme: PropTypes.string
}

export default withTheme(Expander)
