import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import withTheme from '../Theme/withTheme'
import classNames from 'classnames'

class Paper extends PureComponent {
  render () {
    const { link, backgroundImage, logo, theme, name } = this.props

    return (
      <Fragment>
        <li className="MastheadContainer">
          <a
            className={classNames('Masthead', theme && `Masthead-${theme}`)}
            href={link}
            style={{ backgroundImage: 'url(' + backgroundImage + ')' }}
          >
            <img src={logo} alt={name} />
            <span className="MastheadName">{name}</span>
          </a>
        </li>
      </Fragment>
    )
  }
}

Paper.propTypes = {
  theme: PropTypes.string,
  backgroundImage: PropTypes.string,
  logo: PropTypes.string,
  link: PropTypes.string,
  name: PropTypes.string
}

export default withTheme(Paper)
