import React from 'react' // eslint-disable-line no-unused-vars
// Sourced from https://material.io/tools/icons/?style=outline
// svgo ./components/site/layout/component/Primitive/Icon/source --enable=removeXMLNS --enable=removeDimensions --enable=removeTitle --enable=removeStyleElement --multipass --pretty --indent=2

// cache-buster: 52

import Avatar from './source/rewards/avatar.svg'
import ChevronDown from './source/rewards/chevron-down.svg'
import ChevronDownAlt from './source/rewards/chevron-down-alt.svg'
import ChevronLeft from './source/rewards/chevron-left.svg'
import ChevronRight from './source/rewards/chevron-right.svg'
import ChevronUp from './source/rewards/chevron-up.svg'
import ChevronUpAlt from './source/rewards/chevron-up-alt.svg'
import Cross from './source/rewards/cross.svg'
import CrossSmall from './source/rewards/cross-small.svg'
import Email from './source/rewards/email.svg'
import Facebook from './source/rewards/facebook.svg'
import InfoCircle from './source/aus/info-circle.svg'
import Plus from './source/rewards/plus.svg'
import PlusRewards from './source/rewards/plus-rewards.svg'
import PlusRewardsForYou from './source/rewards/plus-rewards-for-you.svg'
import PlusRewardsDark from './source/rewards/plus-rewards-dark.svg'
import Rosette from './source/rewards/rosette.svg'
import SpeechMark from './source/rewards/speech-mark.svg'
import Tick from './source/rewards/tick.svg'
import Telephone from './source/rewards/telephone.svg'
import Twitter from './source/rewards/twitter.svg'
import ChevronDownAlt2 from './source/rewards/chevron-down-alt-2.svg'
import AppleWallet from './source/rewards/add-to-apple-wallet.svg'
import AndroidWallet from './source/rewards/add-to-android-wallet.svg'

const Svgs = {
  Avatar,
  AppleWallet,
  ChevronDown,
  ChevronDownAlt,
  ChevronLeft,
  ChevronRight,
  ChevronUp,
  ChevronUpAlt,
  Cross,
  CrossSmall,
  Email,
  Facebook,
  InfoCircle,
  PlusRewards,
  PlusRewardsForYou,
  Plus,
  SpeechMark,
  PlusRewardsDark,
  Rosette,
  Telephone,
  Tick,
  Twitter,
  ChevronDownAlt2,
  AndroidWallet
}

export default Svgs
