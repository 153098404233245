import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import { Icon } from '../Primitive/Icon'
import Menu from '../Primitive/Menu'

class SeasonalHero extends PureComponent {
  render () {
    const { theme, instanceUrl, showAddToCalendar, showScrollIcon } = this.props
    return (
      <div
        className={classNames('SeasonalHero', theme && `SeasonalHero-${theme}`)}
      >
        <div className="SeasonalHero__Image SeasonalHero__Image--1">
          <picture>
            <source
              media="(min-width: 800px)"
              srcSet="/assets/img/content/seasonal/seasonal-hero-1--desktop.webp"
              type="image/webp"
            />
            <source
              media="(min-width: 800px)"
              srcSet="/assets/img/content/seasonal/seasonal-hero-1--desktop.png"
              type="image/png"
            />

            <source
              srcSet="/assets/img/content/seasonal/seasonal-hero-1--desktop.webp"
              type="image/webp"
            />
            <img
              src="/assets/img/content/seasonal/seasonal-hero-1--mobile.png"
              alt=""
            />
          </picture>
          <div className="SeasonalHero__Content">
            <div className="SeasonalHero__Content__TitleWrapper">
              <div className="SeasonalHero__Content__Title">
                Smash your Chrissy list early with
              </div>
              <h1 className="SeasonalHero__Content__logo">
                <span className="SeasonalHero__Content__logo-1">
                  12 Days of{' '}
                </span>
                <span className="SeasonalHero__Content__logo-2">Rewards</span>
              </h1>
            </div>
            <p className="SeasonalHero__Content__subtitle">
              In the countdown to Christmas, +Rewards are giving you{' '}
              <strong>12 days of rewards</strong>. A new offer goes live each
              day at midday AEDT from the 22nd of November, you can smash a
              Chrissy bauble for your chance to redeem a limited offer or enter
              a member-only competition.
            </p>
            <div className="SeasonalHero__Content__CtaWrapper">
              <a
                className="SeasonalHero__Content__CtaLink"
                href={`${instanceUrl}advent-calendar#advent-calendar`}
              >
                Get cracking
              </a>
              {showAddToCalendar && (
                <Menu
                  theme="rewards"
                  className="SeasonalHero__Content__AddToCalendar"
                  label="Add to calendar"
                >
                  <a
                    target="_blank"
                    className="SeasonalHero__Content__AddToCalendarLink"
                    href={`${instanceUrl}advent-calendar-link?type=ical`}
                  >
                    iCal Calendar
                  </a>
                  <a
                    target="_blank"
                    className="SeasonalHero__Content__AddToCalendarLink"
                    href={`${instanceUrl}advent-calendar-link?type=gcal`}
                  >
                    Google Calendar
                  </a>
                </Menu>
              )}
            </div>
          </div>
        </div>
        <div className="SeasonalHero__Image SeasonalHero__Image--2  js-hero-snow-wrapper">
          <img
            className="SeasonalHero__Baubles"
            src="/assets/img/content/seasonal/baubles.png"
            alt=""
          />
          <picture>
            <source
              media="(min-width: 800px)"
              srcSet="/assets/img/content/seasonal/seasonal-hero-2--desktop.webp"
              type="image/webp"
            />
            <source
              media="(min-width: 800px)"
              srcSet="/assets/img/content/seasonal/seasonal-hero-2--desktop.png"
              type="image/png"
            />
            <source
              srcSet="/assets/img/content/seasonal/seasonal-hero-2--mobile.webp"
              type="image/webp"
            />
            <img
              src="/assets/img/content/seasonal/seasonal-hero-2--mobile.png"
              alt=""
            />
          </picture>
          <div className="SeasonalHero__SnowWrapper">
            <canvas
              className="SeasonalHeroBg__snow js-hero-snow"
              width="100%"
              height="100%"
            />
          </div>
        </div>
        {showScrollIcon && (
          <Icon
            width={29}
            height={12}
            theme={theme}
            type="ChevronDownAlt2"
            onClick={() => {
              const element = document.getElementById('advent-calendar')
              if (element) {
                element.scrollIntoView()
              }
            }}
            a11yText="Scroll to calendar"
            className="SeasonalHero__ScrollIndicator"
          />
        )}
      </div>
    )
  }
}

SeasonalHero.propTypes = {
  theme: PropTypes.string,
  instanceUrl: PropTypes.string,
  showAddToCalendar: PropTypes.bool,
  showScrollIcon: PropTypes.bool
}

export default withTheme(SeasonalHero)
