import React, { Fragment } from 'react'
import PropTypes from 'prop-types'

class BaseFormBuilder extends React.Component {
  constructor (props) {
    super(props)
    this.onChange = this.onChange.bind(this)
    this.setValues = this.setValues.bind(this)
    let formData

    if (this.props.prePopulateData) {
      formData = this.props.prePopulateData
    }

    this.state = {
      waiting: false,
      error: {},
      data: this.props.schema && !formData ? this.props.schema.makeDefault() : formData
    }
  }

  getCheckBoxValues (e) {
    let values = this.state.data[e.currentTarget.name] || new Set()
    if (Array.isArray(values)) {
      values = new Set(values)
    }
    const newValue = e.currentTarget.value
    values.has(newValue) ? values.delete(newValue) : values.add(newValue)
    return values.size > 0 ? Array.from(values) : null
  }

  onChange (e) {
    let targetValue
    e.target.type === 'checkbox' ? targetValue = this.getCheckBoxValues(e) : targetValue = e.currentTarget.value

    this.setState({
      data: { ...this.state.data, [ e.currentTarget.name ]: targetValue }
    })
  }

  setValues (values) {
    this.setState({
      data: { ...this.state.data,
        ...values
      }
    })
  }

  render () {
    return (
      <Fragment />
    )
  }
}

BaseFormBuilder.propTypes = {
  prePopulateData: PropTypes.object,
  schema: PropTypes.object
}

export default BaseFormBuilder
