import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Content from '../Content'
import Carousel from '../Carousel'

class QuoteAus extends PureComponent {
  render () {
    const { quotes } = this.props
    return (
      <div className="Quote Quote-aus">
        <Content center width="small" gutter>
          {quotes.length > 1 ? (
            <Carousel autoplay>
              {quotes.map((item, i) => (
                <div key={i} className="QuoteItem">
                  <blockquote>
                    <p className="QuoteText">{item.quote}</p>
                    <cite className="QuoteAuthor">{item.author}</cite>
                  </blockquote>
                </div>
              ))}
            </Carousel>
          ) : (
            <div className="QuoteItem">
              <blockquote>
                <p className="QuoteText">{quotes[0].quote}</p>
                <cite className="QuoteAuthor">{quotes[0].author}</cite>
              </blockquote>
            </div>
          )}
        </Content>
      </div>
    )
  }
}

QuoteAus.propTypes = {
  quotes: PropTypes.array.isRequired
}

export default QuoteAus
