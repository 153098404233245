import React, { PureComponent } from 'react'
import { oneOf, string } from 'prop-types'
import classNames from 'classnames'

import withTheme from '../../../../Theme/withTheme'

import NativeCheckControl from '../NativeCheckControl'

export class CustomCheckControl extends PureComponent {
  render () {
    const { status, theme, type } = this.props

    const componentClassName = classNames(
      'CustomCheckControl',
      theme && `CustomCheckControl-${theme}`,
      type && type,
      status && status
    )

    return (
      <span className={componentClassName}>
        <NativeCheckControl
          className={classNames(
            'CustomCheckControlControl',
            theme && `CustomCheckControlControl-${theme}`
          )}
          {...this.props}
        />
        <span
          className={classNames(
            'CustomCheckControlIndicator',
            theme && `CustomCheckControlIndicator-${theme}`
          )}
        />
      </span>
    )
  }
}

CustomCheckControl.displayName = 'CustomCheckControl'

CustomCheckControl.propTypes = {
  status: oneOf([ 'error', 'notice', 'success', 'warning' ]),
  type: oneOf([ 'checkbox', 'radio' ]).isRequired,
  theme: string
}

export default withTheme(CustomCheckControl)
