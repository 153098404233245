import throttle from 'lodash.throttle'

export default () => {
  const theme = document.body.dataset.theme

  const values = {
    'stickyHeader': {
      'aus': 130,
      'rewards': 0,
      'className': 'sticky-header'
    },
    'mastheadLogoInHeader': {
      'rewards': 440,
      'className': 'masthead-logo-in-header-on-mobile'
    },
    'collapseHeaderOnMobile': {
      'rewards': 900,
      'className': 'collapsed-header-on-mobile'
    }
  }

  const stickIt = () => {
    const scrollTop = (document.documentElement && document.documentElement.scrollTop) || document.body.scrollTop
    Object.keys(values).map(key => {
      const obj = values[key]
      scrollTop > obj[theme]
        ? document.body.classList.add(obj.className)
        : document.body.classList.remove(obj.className)
    })
  }

  window.addEventListener('scroll', throttle(stickIt, 80))
}
