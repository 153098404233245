import React from 'react'
import PropTypes from 'prop-types'
import BasicRedeemButton from './BasicRedeemButton'
import RedemptionSummary from '../../../../layout/component/RedemptionSummary'
import FormBuilder from '../../../../layout/component/FormBuilder/FormBuilder'

const BasicRedemptionArea = (props) => {
  const {
    formData,
    redemptionCtaText,
    redemptionInfoLoaded,
    loadRedemptionInfo,
    submitForm,
    thankYouData,
    prePopulateData,
    offerId,
    showMobileWallet,
    instancePath
  } = props
  const ParentComponent = formData ? FormBuilder : BasicRedeemButton
  return (
    <ParentComponent
      ctaText={redemptionCtaText}
      buttonClicked={redemptionInfoLoaded}
      onButtonClick={loadRedemptionInfo}
      form={formData}
      data={prePopulateData}
      onSubmit={submitForm}
      {...props}
      submissionSuccess={redemptionInfoLoaded}
    >
      <RedemptionSummary
        {...thankYouData}
        offerId={offerId}
        showMobileWallet={showMobileWallet}
        instancePath={instancePath}
      />
    </ParentComponent>
  )
}

BasicRedemptionArea.propTypes = {
  formData: PropTypes.object,
  prePopulateData: PropTypes.object,
  redemptionCtaText: PropTypes.string,
  thankYouData: PropTypes.object,
  redemptionInfoLoaded: PropTypes.bool,
  showMobileWallet: PropTypes.bool,
  loadRedemptionInfo: PropTypes.func,
  submitForm: PropTypes.func,
  offerId: PropTypes.string,
  instancePath: PropTypes.string
}

export default BasicRedemptionArea
