import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import determineWidthStyle from '../../../../widgets/offer-grid/lib/width-style-determiner'
import { GridItem } from '../Grid'
import Summary from '../Summary'
import Carousel from '../Carousel'

class OfferCarousel extends PureComponent {
  render () {
    const { items, width, breakpoint, additionalHeadlineClass, additionalOverlayClass } = this.props

    return (
      <Carousel wrapper breakpoint={breakpoint}>
        {items.map((item, i) =>
          <GridItem key={`summary-${i}`} sizes={[ determineWidthStyle(width, items.length) ]}>
            <Summary additionalHeadlineClass={additionalHeadlineClass} additionalOverlayClass={additionalOverlayClass} {...item} width={width} />
          </GridItem>
        )}
      </Carousel>
    )
  }
}

OfferCarousel.propTypes = {
  items: PropTypes.array,
  width: PropTypes.string,
  breakpoint: PropTypes.string,
  additionalHeadlineClass: PropTypes.string,
  additionalOverlayClass: PropTypes.string
}

export default OfferCarousel
