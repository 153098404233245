import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Summary from '../Primitive/Summary'
import Content from '../Primitive/Content'
import { Grid, GridItem } from '../Primitive/Grid'
import determineWidthStyle from '../../../../../components/site/widgets/offer-grid/lib/width-style-determiner'
import determineHeadlineSize from '../../../../../components/site/widgets/offer-grid/lib/headline-size-determiner'
import determineOverlaySize from '../../../../../components/site/widgets/offer-grid/lib/overlay-size-determiner'
import FilterMenu from '../Primitive/FilterMenu'
import Title from '../Title'
import OffsetAnchor from '../Primitive/OffsetAnchor'

const SortableOffersRewards = (props) => {
  const {
    title,
    emphasisedWordLocation,
    limit,
    width,
    menuIsOpen,
    selected,
    toggleMenu,
    selectItem,
    menu
  } = props
  const offers = limit ? props.offers.slice(0, limit) : props.offers
  const heroOffer = width === 'OneThree' ? offers.shift() : null
  return (
    <Fragment>
      <div className="SortableOffersFilter">
        <Content center gutter>
          <FilterMenu
            menu={menu}
            menuIsOpen={menuIsOpen}
            selected={selected}
            toggleMenu={toggleMenu}
            selectItem={selectItem}
          />
        </Content>
      </div>
      <OffsetAnchor identifier="page-content" />
      <Title
        title={title}
        emphasisedWordLocation={emphasisedWordLocation}
        size="Large"
      />
      <Content center gutter>
        <Grid flex>
          { heroOffer &&
            <GridItem>
              <Summary
                image={{ url: heroOffer.imageUrl }}
                __fullUrlPath={heroOffer.url}
                tag={heroOffer.category}
                shortHeadline={heroOffer.shortHeadline}
                displayShortHeadline={heroOffer.displayShortHeadline}
                keyFactsTitle={heroOffer.keyFactsTitle}
                extraInfo={heroOffer.extraInfo}
                status={heroOffer.status}
                width="One"
                FeaturedSummary
              />
            </GridItem>
          }
          {offers.map(({
            _id,
            imageUrl,
            url,
            category,
            shortHeadline,
            displayShortHeadline,
            keyFactsTitle,
            extraInfo,
            status,
            showCountdownOverlay,
            openingDate,
            closingDate
          }) => (
            <GridItem key={_id} sizes={[ determineWidthStyle(width, offers.length) ]}>
              <Summary
                image={{ url: imageUrl }}
                __fullUrlPath={url}
                tag={category}
                shortHeadline={shortHeadline}
                displayShortHeadline={displayShortHeadline}
                width={width}
                keyFactsTitle={keyFactsTitle}
                extraInfo={extraInfo}
                status={status}
                openingDate={openingDate}
                closingDate={closingDate}
                showCountdownOverlay={showCountdownOverlay}
                additionalHeadlineClass={determineHeadlineSize(width, offers.length)}
                additionalOverlayClass={determineOverlaySize(width, offers.length)}
              />
            </GridItem>
          ))}
        </Grid>
      </Content>
    </Fragment>
  )
}

SortableOffersRewards.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
  offers: PropTypes.array,
  width: PropTypes.string,
  toggleMenu: PropTypes.func,
  selectItem: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  selected: PropTypes.string,
  emphasisedWordLocation: PropTypes.string,
  menu: PropTypes.array
}

SortableOffersRewards.defaultProps = {
  offers: []
}

export default SortableOffersRewards
