import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import { Grid, GridItem } from '../Primitive/Grid'
import AdventCalendarItem from './AdventCalendarItem'
import AdventCalendarModal from './AdventCalendarModal'

class AdventCalendar extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      open: false,
      openOfferIndex: null
    }
    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle (offerIndex) {
    const isOpen = this.state.open
    if (!isOpen) {
      this.setState({ open: !isOpen, openOfferIndex: offerIndex })
    } else {
      this.setState({
        open: !isOpen,
        openOfferIndex: null
      })
    }
  }

  render () {
    const { theme, items } = this.props
    const { open, openOfferIndex } = this.state

    return (
      <div
        className={classNames(
          'AdventCalendar',
          theme && `AdventCalendar-${theme}`
        )}
        id="advent-calendar"
      >
        <div className="AdventCalendar__Header">
          <h2 className="AdventCalendar__Title">
            12 days of <span>Rewards</span>
          </h2>
          <p>
            With over $190,000 worth of prizes available you can smash your
            Chrissy list early! All offers open at midday AEDT from the 22nd of
            November. Offers are available to +Rewards members nationally.
          </p>
        </div>
        <Grid hcentered gutter="small" className="AdventCalendar__Calendar">
          {items.map((item, i) => {
            const { offer, imageUrls, color, comingSoonMessage } = item
            const image =
              offer.status === 'Redeemed'
                ? imageUrls.redeemed
                : imageUrls.available
            return (
              <GridItem
                sizes={[ 'whole', 'tablet-one-half', 'desktop-one-third' ]}
                className="AdventCalendar__Item"
                key={`Calendar-item-${i}`}
              >
                <AdventCalendarItem
                  comingSoonMessage={comingSoonMessage}
                  state={offer.status}
                  image={image}
                  title={offer.displayShortHeadline}
                  color={color}
                  offerUrl={offer.url}
                  onClick={() => this.handleToggle(i)}
                />
              </GridItem>
            )
          })}
        </Grid>
        {open && (
          <AdventCalendarModal
            handleToggle={this.handleToggle}
            offer={items[openOfferIndex].offer}
            revealText={items[openOfferIndex].revealText}
            theme={theme}
          />
        )}
      </div>
    )
  }
}

AdventCalendar.propTypes = {
  theme: PropTypes.string,
  items: PropTypes.array
}

export default withTheme(AdventCalendar)
