import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import RedemptionButton from '../RedemptionButton'
import withTheme from '../Theme/withTheme'
import Button from '../Primitive/Button'
import LoginSubscribe from '../LoginSubscribe'

class KeyFacts extends PureComponent {
  render () {
    const { theme, title, redemptionClicked, items, offerId, ctaData, stateData, logoUrl, hasRedemptions, timezone } = this.props
    const { primaryCtaText, secondaryCtaLink, secondaryCtaText, openSecondaryLinkInNewWindow, isCompetition, redemptionType } = ctaData || {}
    const calendarUrl = (index, type) => `/calendar?id=${offerId}&index=${index}&type=${type}`

    const KeyFactsTitle = {
      'aus': 'Details',
      'rewards': title
    }

    const isAusLoggedOut = theme === 'aus' && stateData.state === 'notLoggedIn'
    return (
      <div className={classNames(
        'KeyFacts',
        theme && `KeyFacts-${theme}`,
        isAusLoggedOut && 'KeyFactsLoggedOut'
      )}>
        {isAusLoggedOut &&
          <Fragment>
            <img className="KeyFactsLogo" src={logoUrl} alt="The Australian Plus" />
            <div className="KeyFactsTitle">Subscribe or log in to <i>The Australian</i> to get access to The Australian <i>Plus</i></div>
            <div className="KeyFactsItems">
              <div className="KeyFactsItem">
                Unlock access to award-winning journalism today.
              </div>
            </div>
            <div className="js-login-subscribe" data-props={JSON.stringify({ block: true })}>
              <LoginSubscribe block />
            </div>
          </Fragment>
        }
        {!isAusLoggedOut &&
          <Fragment>
            <h2 className="KeyFactsTitle">{KeyFactsTitle[theme]}</h2>
            <div className="KeyFactsItems">
              {theme === 'aus' && title && (
                <div className="KeyFactsItemTitle">{title}</div>
              )}
              {items && items.length > 0 && items.map((item, i) => (
                <div key={`KeyFactsItem-${i}`} className="KeyFactsItem">
                  {item.key && <h3 className="KeyFactsItemKey">{item.key}</h3>}
                  <div
                    dangerouslySetInnerHTML={{ __html: item.value }}
                    className="KeyFactsItemValue"
                  />
                  {theme === 'aus' && item.startDate && (
                    <div className="KeyFactsLinkWrapper">
                      <a className="js-toggle-switch">+ Add to calendar</a>
                      <div className="js-toggle-content is-hidden">
                        <a target="_blank" className="KeyFactsLink" href={calendarUrl(i, 'ical')}>iCal Calendar</a>
                        <a target="_blank" className="KeyFactsLink" href={calendarUrl(i, 'gcal')}>Google Calendar</a>
                      </div>
                    </div>
                  )}
                </div>
              ))}
            </div>
            {hasRedemptions && (
              <Fragment>
                <RedemptionButton redemptionClicked={redemptionClicked} {...stateData} redemptionType={redemptionType} ctaText={primaryCtaText} isCompetition={isCompetition} timezone={timezone} block />
                {secondaryCtaLink && <Button primary uppercase block href={secondaryCtaLink} target={openSecondaryLinkInNewWindow ? '_blank' : ''}>{secondaryCtaText}</Button>}
              </Fragment>
            )}
          </Fragment>
        }
      </div>
    )
  }
}

KeyFacts.propTypes = {
  offerId: PropTypes.string,
  theme: PropTypes.string,
  title: PropTypes.string,
  hasRedemptions: PropTypes.bool,
  redemptionClicked: PropTypes.func,
  logoUrl: PropTypes.string,
  ctaData: PropTypes.object,
  stateData: PropTypes.object,
  timezone: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
}

export default withTheme(KeyFacts)
