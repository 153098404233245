import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import Carousel from '../Primitive/Carousel'
import Content from '../Primitive/Content'

class OfferImages extends PureComponent {
  render () {
    const { theme, images, offerTitle, isRehydrated } = this.props

    return (
      <div className={classNames(
        'OfferImages',
        theme && `OfferImages-${theme}`
      )}>
        <Content center gutter={theme === 'rewards'}>
          {images.length === 1 ? (
            <img src={images[0].url} alt={offerTitle} />
          ) : (
            <Carousel autoplay>
              {images.map((image, i) => (
                <img className={!isRehydrated ? 'is-hidden' : ''} key={`image${i}`} src={image.url} alt={offerTitle} />
              ))}
            </Carousel>
          )}
        </Content>
      </div>
    )
  }
}

OfferImages.propTypes = {
  theme: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  offerTitle: PropTypes.string,
  isRehydrated: PropTypes.bool
}

export default withTheme(OfferImages)
