import React, { PureComponent } from 'react'
import { node, string } from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'

class Notification extends PureComponent {
  render () {
    const { children, status, theme } = this.props
    if (!children) return null
    return (
      <div
        className={classNames(
          'Notification',
          theme && `Notification-${theme}`,
          status && status
        )}
      >
        { status === 'OptOut' ? (
          <div className="NotificationInner">
            {children}
          </div>)
          : children
        }
      </div>
    )
  }
}

Notification.displayName = 'Notification'

Notification.propTypes = {
  children: node,
  status: string,
  theme: string
}

export default withTheme(Notification)
