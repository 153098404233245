import React from 'react'
import PropTypes from 'prop-types'
import Modal from '../Modal'
import Content from '../Content'
import { Grid, GridItem } from '../Grid'
import Figure from '../Figure'
import Carousel from '../Carousel'

const GalleryImages = ({ images, layoutType, openModal, showOverlayOnLastItem, overflow }) => {
  const layoutMap = {
    'one': [ [ 'one-whole' ] ],
    'two': Array(2).fill([ 'one-half' ]),
    'oneAndTwo': [ [ 'one-whole' ], ...Array(2).fill([ 'one-half' ]) ]
  }

  return (
    <Grid>
      {images.map((image, i) => {
        const lastItem = i === images.length - 1
        return (
          <GridItem key={i} sizes={layoutMap[layoutType][i]}>
            <Figure onClick={lastItem && showOverlayOnLastItem && openModal} {...image}>
              {lastItem && showOverlayOnLastItem && <span>{`+${overflow}`}</span>}
            </Figure>
          </GridItem>
        )
      })}
    </Grid>
  )
}

GalleryImages.propTypes = {
  layoutType: PropTypes.string,
  images: PropTypes.array,
  openModal: PropTypes.func,
  showOverlayOnLastItem: PropTypes.bool,
  overflow: PropTypes.oneOfType([ PropTypes.number, PropTypes.bool ])
}

class Gallery extends React.Component {
  constructor () {
    super()
    this.toggleModalState = this.toggleModalState.bind(this)
    this.onModalOpen = this.onModalOpen.bind(this)
    this.state = {
      modalIsOpen: false
    }
    this.carouselRef = {}
  }

  toggleModalState () {
    const currentState = this.state.modalIsOpen
    this.setState({ modalIsOpen: !currentState })
  }

  onModalOpen () {
    this.carouselRef.flickity.resize()
  }

  render () {
    const { theme, galleryImageData, images } = this.props
    let index = 0

    const addImage = (i, arr) => {
      arr.push(images[i])
      index += 1
    }

    return (
      <Content center gutter width={theme === 'aus' ? 'small' : undefined}>
        {galleryImageData.layout.map((layoutType, i) => {
          const sectionImages = []
          !images[index].hidden && addImage(index, sectionImages)

          if (layoutType === 'two' || layoutType === 'oneAndTwo') {
            !images[index].hidden && addImage(index, sectionImages)
          }

          if (layoutType === 'oneAndTwo') {
            !images[index].hidden && addImage(index, sectionImages)
          }

          // show overlay if next item is hidden
          const allVisible = sectionImages.map(image => !image.hidden)

          const showOverlayOnLastItem = (images[index] && images[index].hidden) || !allVisible

          return (
            <GalleryImages
              showOverlayOnLastItem={showOverlayOnLastItem}
              openModal={this.toggleModalState}
              key={i}
              layoutType={layoutType}
              images={sectionImages}
              overflow={showOverlayOnLastItem && images.length - index}
            />
          )
        })}
        {this.state.modalIsOpen &&
          <Modal gallery onOpen={this.onModalOpen} handleClose={this.toggleModalState} theme={this.theme}>
            <Carousel childRef={this.carouselRef}>
              {images.map(image => (
                <img src={image.imgUrl} alt={image.caption || 'Gallery Image'} />
              ))}
            </Carousel>
          </Modal>
        }
      </Content>
    )
  }
}

Gallery.propTypes = {
  theme: PropTypes.string,
  images: PropTypes.array,
  galleryImageData: PropTypes.object
}

export default Gallery
