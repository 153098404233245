import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'

export class ShrinkWrapItem extends PureComponent {
  render () {
    const { children, shrink, className } = this.props
    return (
      <span
        className={classNames('ShrinkWrapItem', shrink && 'ShrinkWrapItem-shrink', className)}
      >
        {children}
      </span>
    )
  }
}

ShrinkWrapItem.displayName = 'ShrinkWrapItem'

ShrinkWrapItem.propTypes = {
  children: node.isRequired,
  shrink: bool,
  className: string
}

class ShrinkWrap extends PureComponent {
  render () {
    const { children, element, fullWidth, vAlign, className } = this.props
    const ShrinkWrapEl = element || 'div'

    return (
      <ShrinkWrapEl
        className={classNames(
          'ShrinkWrap',
          fullWidth && 'fullWidth',
          vAlign && vAlign,
          className
        )}
      >
        {children}
      </ShrinkWrapEl>
    )
  }
}

ShrinkWrap.displayName = 'ShrinkWrap'

ShrinkWrap.propTypes = {
  children: node.isRequired,
  element: string,
  fullWidth: bool,
  vAlign: oneOf([ 'top', 'middle', 'bottom' ]),
  className: string
}

export default ShrinkWrap
