import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import withTheme from '../component/Theme/withTheme'

import NavigationAus from './NavigationAus'
import NavigationRewards from './NavigationRewards'

class Navigation extends PureComponent {
  render () {
    const { theme } = this.props

    const Component = {
      'aus': <NavigationAus {...this.props} />,
      'rewards': <NavigationRewards {...this.props} />
    }

    return Component[theme]
  }
}

Navigation.propTypes = {
  theme: PropTypes.string,
  currentUrl: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object)
}

export default withTheme(Navigation)
