import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '../Button'
import withTheme from '../../../component/Theme/withTheme'
import Icon from '../Icon'

class FilterMenuRewards extends PureComponent {
  render () {
    const { theme, toggleMenu, selectItem, selected, menuIsOpen, menu } = this.props
    return (
      <div className={classNames(
        'FilterMenu',
        theme && `FilterMenu-${theme}`,
        menuIsOpen && 'open'
      )}>
        <span>Sort by:</span>
        <Button
          onClick={toggleMenu}
          className="FilterMenuButton"
          ref={toggle => { this.toggle = toggle }}
        >
          <span>{selected || 'Sort By'}</span>
          <Icon type="ChevronDownAlt" width={10} height={6} />
        </Button>
        <div className={classNames('FilterMenuChildren')}>
          {menu.map((item, i) => (
            <span
              className="FilterMenuItem"
              onClick={() => selectItem(item)}
              key={`FilterMenuItem${i}`}
            >
              <span className="FilterMenuIcon">
                { item.text === selected &&
                  <Icon type="Tick" />
                }
              </span>
              {item.text}
            </span>
          ))}
        </div>
      </div>
    )
  }
}

FilterMenuRewards.propTypes = {
  theme: PropTypes.string,
  toggleMenu: PropTypes.func,
  selectItem: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  selected: PropTypes.string,
  menu: PropTypes.array
}

export default withTheme(FilterMenuRewards)
