import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import SummaryAus from './SummaryAus'
import SummaryRewards from './SummaryRewards'
import withTheme from '../../../component/Theme/withTheme'

class Summary extends PureComponent {
  render () {
    const { theme, split } = this.props
    const Component = {
      'aus': <SummaryAus split={split} {...this.props} />,
      'rewards': <SummaryRewards {...this.props} />
    }
    return (
      Component[theme]
    )
  }
}

Summary.propTypes = {
  theme: PropTypes.string,
  widget: PropTypes.object,
  split: PropTypes.string
}

export default withTheme(Summary)
