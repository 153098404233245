import React from 'react'
import PropTypes from 'prop-types'
import BaseFormBuilder from '../form-builder/BaseFormBuilder'
import RedemptionHistory from '../../../site/widgets/offer-redemption-history/component/RedemptionHistoryItem'

class RedemptionHistoryItem extends BaseFormBuilder {
  constructor (props) {
    super(props)
    this.handleSendEmail = this.handleSendEmail.bind(this)
    this.state = {}
  }

  async handleSendEmail () {
    this.setState({ emailRequest: 'loading' })
    try {
      const res = await fetch(`${this.props.instancePath}api/payment/resend/${this.props.offer._id}`, {
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        method: 'POST'
      })
      if (res.status !== 200) return this.handleSendEmailError()
      this.setState({ emailRequest: 'success' })
    } catch (e) {
      this.handleSendEmailError()
    }
  }

  handleSendEmailError () {
    this.setState({ emailRequest: 'failed' })
  }

  render () {
    return <RedemptionHistory
      resendEmailOnClick={this.handleSendEmail}
      {...this.props}
      {...this.state} />
  }
}

RedemptionHistoryItem.propTypes = {
  instancePath: PropTypes.string
}

export default RedemptionHistoryItem
