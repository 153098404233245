import React, { PureComponent } from 'react'
import { bool, node, string } from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'
import SmartLink from '../SmartLink'

export class Button extends PureComponent {
  render () {
    const { alternative, alternativeInverted, block, blockMobile, black, login, primary, secondary, outline, uppercase, capitalCase, center, className, disabled, theme, textButton, primaryInverse, ...other } = this.props
    return (
      <SmartLink
        className={classNames(
          'Button',
          theme && `Button-${theme}`,
          block && 'block',
          blockMobile && 'blockMobile',
          disabled && 'disabled',
          primary && 'primary',
          secondary && 'secondary',
          uppercase && 'uppercase',
          capitalCase && 'capitalCase',
          center && 'center',
          alternative && 'alternative',
          alternativeInverted && 'alternativeInverted',
          textButton && 'textbutton',
          black && 'black',
          login && 'login',
          outline && 'outline',
          primaryInverse && 'primaryInverse',
          className
        )}
        disabled={disabled}
        {...other}
      />
    )
  }
}

Button.displayName = 'Button'

Button.propTypes = {
  block: bool,
  blockMobile: bool,
  primary: bool,
  secondary: bool,
  uppercase: bool,
  capitalCase: bool,
  alternative: bool,
  alternativeInverted: bool,
  black: bool,
  login: bool,
  children: node.isRequired,
  className: string,
  disabled: bool,
  theme: string,
  center: bool,
  textButton: bool,
  outline: bool,
  primaryInverse: bool
}

export default withTheme(Button)
