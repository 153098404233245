import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withTheme from '../../Theme/withTheme'
import animationData from '../animation.json'
import lottie from 'lottie-web'
import Overlay from '../../Primitive/Overlay'

class AdventCalendarModal extends PureComponent {
  constructor (props) {
    super(props)
    this.lottie = null
  }

  componentDidMount () {
    this.lottie = lottie.loadAnimation({
      // Clone JSON to prevent a memory leak
      // https://github.com/airbnb/lottie-web/issues/2151
      animationData: JSON.parse(JSON.stringify(animationData)),
      loop: false,
      renderer: 'svg',
      container: document.querySelector('#advent-calendar-animation'),
      autoplay: true
    })
  }

  componentWillUnmount () {
    this.lottie.destroy()
  }

  render () {
    const { offer, handleToggle, theme, revealText } = this.props
    return (
      <Overlay className={`AdventCalendarModal-${theme}`} handleClose={handleToggle}>
        <div className="AdventCalendarModal__Modal">
          <div className="AdventCalendarModal__Animation">
            <div id="advent-calendar-animation" />
            <div className="AdventCalendarModal__DiscountWrapper">
              <div
                style={{ animationDelay: `${1}s` }}
                className="AdventCalendarModal__Discount"
              >
                {revealText}
              </div>
            </div>
          </div>

          <div className="AdventCalendarModal__Offer">
            <div className="AdventCalendarModal__OfferImage">
              <img src={offer.imageUrl} alt={offer.shortHeadline} />
            </div>
            <div className="AdventCalendarModal__OfferContent">
              <h3 dangerouslySetInnerHTML={{ __html: offer.displayShortHeadline }} />
              <a href={offer.url}>Redeem now</a>
            </div>
          </div>
        </div>
      </Overlay>
    )
  }
}

AdventCalendarModal.propTypes = {
  offer: PropTypes.object.isRequired,
  handleToggle: PropTypes.func.isRequired,
  theme: PropTypes.string.isRequired,
  revealText: PropTypes.string.isRequired
}

export default withTheme(AdventCalendarModal)
