import React from 'react'
import ReactDOM from 'react-dom'
import cookieParser from 'cookie-parse'
import UserActions from '../../../site/layout/component/UserActions'
import Hero from '../../../site/layout/component/Hero'
import LoginSubscribe from '../../../site/layout/component/LoginSubscribe'
import NotLoggedInButtonArea from '../../../site/layout/component/NotLoggedInButtonArea'
import NotEligibleButtonArea from '../../../site/layout/component/NotEligibleButtonArea'
import TextWithImageCta from '../../../site/layout/component/Primitive/TextWithImageCta'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'

export default (serviceLocator) => {
  document.addEventListener('DOMContentLoaded', () => {
    window._envq && window._envq.push(() => {
      window.newscdn.envoy.subscribe('envoy/identity/user/details', response => {
        const firstName = window.newscdn.envoy.config('identity.aclResponse.userDetails.firstname')
        const cookies = cookieParser.parse(document.cookie)
        const hasLoggedInSession = cookies.sid
        // this is here to ensure the user is in the same state between idaas and our platform
        // if for some reason IDAAS think they are logged in, but our system doesn't, try and log them in
        // if for some reason IDAAS thinks they are logged out, but our system thinks they are logged in, log them our of our system
        if (!hasLoggedInSession && firstName) {
          window.location = '/login'
          return
        } else if (hasLoggedInSession && !firstName) {
          window.location = '/auth/logout?returnTo=' + window.location.href
          return
        }

        const loginHandler = (e) => {
          e.preventDefault()
          window.newscdn.envoy.publish('envoy/identity/login/start')
        }
        const subscribeHandler = (e) => {
          e.preventDefault()
          window.open(serviceLocator.subscribeUrl)
        }
        const myAccountHandler = (e) => {
          e.preventDefault()
          const myAccountUrl = window.newscdn.envoy.config('identity.ninbar.myaccount-url')
          window.open(myAccountUrl)
        }
        const logoutHandler = (e) => {
          e.preventDefault()
          window.newscdn.envoy.publish('envoy/identity/logout/start')
        }

        const theme = document.querySelector('body').dataset.theme

        document.querySelectorAll('.js-subscriber-benefits-widget').forEach(appNode => {
          const props = JSON.parse(appNode.dataset.props)
          ReactDOM.render(
            <ThemeContext.Provider value={theme}>
              <TextWithImageCta {...props} loginOnClick={loginHandler} />
            </ThemeContext.Provider>,
            appNode
          )
        })

        document.querySelectorAll('.js-not-logged-in-button-area').forEach(appNode => {
          const block = appNode.dataset.block === 'true'
          ReactDOM.render(
            <ThemeContext.Provider value={theme}>
              <NotLoggedInButtonArea block={block} loginOnClick={loginHandler} subscribeOnClick={subscribeHandler} />
            </ThemeContext.Provider>,
            appNode
          )
        })

        document.querySelectorAll('.js-not-eligible-button-area').forEach(appNode => {
          const block = appNode.dataset.block === 'true'
          ReactDOM.render(
            <ThemeContext.Provider value={theme}>
              <NotEligibleButtonArea block={block} subscribeOnClick={subscribeHandler} />
            </ThemeContext.Provider>,
            appNode
          )
        })

        document.querySelectorAll('.js-login-subscribe').forEach(appNode => {
          const props = JSON.parse(appNode.dataset.props)
          ReactDOM.render(
            <ThemeContext.Provider value={theme}>
              <LoginSubscribe loginOnClick={loginHandler} subscribeOnClick={subscribeHandler} {...props} />
            </ThemeContext.Provider>,
            appNode
          )
        })

        document.querySelectorAll('.js-hero').forEach(appNode => {
          const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
          ReactDOM.render(
            <ThemeContext.Provider value={theme}>
              <Hero {...props} loginOnClick={loginHandler} subscribeOnClick={subscribeHandler} isRehydrated />
            </ThemeContext.Provider>,
            appNode
          )
        })

        document.querySelectorAll('.js-user-menu').forEach(appNode => {
          ReactDOM.render(
            <ThemeContext.Provider value={theme}>
              <UserActions
                firstName={firstName || ''}
                handleLogin={loginHandler}
                handleSubscribe={subscribeHandler}
                handleMyAccount={myAccountHandler}
                handleLogout={logoutHandler}
                paperName={appNode.dataset.paperName}
                paperUrl={appNode.dataset.paperUrl}
                instances={JSON.parse(appNode.dataset.instances)}
                instancePath={serviceLocator.instancePath}
              />
            </ThemeContext.Provider>,
            appNode
          )
        })
      })
    })
  })
}
