import React from 'react'
import ReactDOM from 'react-dom'
import FilterMenu from '../../../../components/site/layout/component/Primitive/FilterMenu'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'
const debug = require('debug')('filterMenu')
export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-filter-menu').forEach(appNode => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      const theme = document.querySelector('body').dataset.theme
      ReactDOM.render(
        <ThemeContext.Provider value={theme}>
          <FilterMenu {...props} />
        </ThemeContext.Provider>,
        appNode
      )
    })
  })
}
