import React from 'react' // eslint-disable-line no-unused-vars
// Sourced from https://material.io/tools/icons/?style=outline
// svgo ./components/site/layout/component/Primitive/Icon/source --enable=removeXMLNS --enable=removeDimensions --enable=removeTitle --enable=removeStyleElement --multipass --pretty --indent=2

import Avatar from './source/aus/avatar.svg'
import Close from './source/aus/close.svg'
import ChevronDown from './source/aus/chevron-down.svg'
import ChevronUp from './source/aus/chevron-up.svg'
import Email from './source/aus/email.svg'
import Facebook from './source/aus/facebook.svg'
import Hamburger from './source/aus/hamburger.svg'
import InfoCircle from './source/aus/info-circle.svg'
import Minus from './source/aus/minus.svg'
import Plus from './source/aus/plus.svg'
import Search from './source/aus/search.svg'
import TheAustralian from './source/aus/the-australian.svg'
import Twitter from './source/aus/twitter.svg'
import AndroidWallet from './source/aus/add-to-android-wallet.svg'

const Svgs = {
  Avatar,
  ChevronDown,
  ChevronUp,
  Close,
  Email,
  Facebook,
  Hamburger,
  InfoCircle,
  Minus,
  Plus,
  Search,
  TheAustralian,
  Twitter,
  AndroidWallet
}

export default Svgs
