import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import moment from 'moment-timezone'

import withTheme from '../Theme/withTheme'
import Button from '../Primitive/Button'
import Prose from '../Primitive/Prose'
import NotLoggedInButtonArea from '../NotLoggedInButtonArea'
import NotEligibleButtonArea from '../NotEligibleButtonArea'

class RedemptionButton extends PureComponent {
  render () {
    const {
      ctaText,
      state,
      offerStatus,
      block,
      availableDate,
      theme,
      isCompetition,
      redemptionClicked,
      hasRedeemed,
      timezone,
      seasonal
    } = this.props

    const redemptionText = isCompetition ? 'Entered' : 'Redeemed'
    let formattedAvailableDate = null
    let formattedFromNowDate = null

    if (availableDate) {
      const date = moment(availableDate).tz(timezone)
      formattedAvailableDate = date.format('ll [at] LT z')
      formattedFromNowDate = date.fromNow()
    }
    return (
      <div
        className={classNames(
          'RedemptionButton',
          theme && `RedemptionButton-${theme}`,
          seasonal && 'RedemptionButton--seasonal'
        )}
      >
        {state === 'notLoggedIn' && (
          <div className="js-not-logged-in-button-area" data-block={block}>
            <NotLoggedInButtonArea block={block} />
          </div>
        )}
        {state === 'notEligible' && (
          <div className="js-not-eligible-button-area" data-block={block}>
            <NotEligibleButtonArea block={block} />
          </div>
        )}
        {state === 'disabled' && offerStatus === 'Coming soon' && (
          <Fragment>
            <Button
              className="RedemptionButtonCTA RedemptionButtonCTAComingSoon"
              block={block}
              black={theme === 'rewards'}
              capitalCase
              disabled
            >
              {theme === 'aus' ? `Redeemable ${formattedFromNowDate}` : `Coming soon` }
            </Button>
            {formattedAvailableDate && (
              <Prose className={classNames('RedemptionButtonFeedback')}>
                <p>Available {formattedAvailableDate}</p>
              </Prose>
            )}
          </Fragment>
        )}
        {((state === 'disabled' &&
          offerStatus !== 'Coming soon' &&
          hasRedeemed) ||
          (state === 'redeemable' && hasRedeemed)) && (
          <Button
            block={block}
            className="RedemptionButtonCTA"
            disabled
            capitalCase
          >
            {redemptionText}
          </Button>
        )}
        {state === 'disabled' && offerStatus !== 'Coming soon' && !hasRedeemed && (
          <Button
            block={block}
            className="RedemptionButtonCTA"
            disabled
            capitalCase
          >
            {offerStatus}
          </Button>
        )}
        {state === 'redeemable' && !hasRedeemed && (
          <Button
            onClick={redemptionClicked}
            block={block}
            className="RedemptionButtonCTA"
            primary
            capitalCase
            href="#redemption"
          >
            {ctaText}
          </Button>
        )}
      </div>
    )
  }
}

RedemptionButton.propTypes = {
  ctaText: PropTypes.string,
  state: PropTypes.string,
  availableDate: PropTypes.object,
  offerStatus: PropTypes.string,
  hasRedeemed: PropTypes.bool,
  isCompetition: PropTypes.bool,
  block: PropTypes.bool,
  theme: PropTypes.string,
  redemptionClicked: PropTypes.func,
  timezone: PropTypes.string,
  seasonal: PropTypes.bool
}

export default withTheme(RedemptionButton)
