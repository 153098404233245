import React from 'react'
import ReactDOM from 'react-dom'
import cookieParser from 'cookie-parse'
import UserActions from '../../../site/layout/component/UserActions'
import Hero from '../../../site/layout/component/Hero'
import LoginSubscribe from '../../../site/layout/component/LoginSubscribe'
import TextWithImageCta from '../../../site/layout/component/Primitive/TextWithImageCta'
import NotLoggedInButtonArea from '../../../site/layout/component/NotLoggedInButtonArea'
import NotEligibleButtonArea from '../../../site/layout/component/NotEligibleButtonArea'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'

export default (serviceLocator) => {
  document.addEventListener('DOMContentLoaded', () => {
    const rampartConfigEl = document.querySelector('.js-rampart-config')
    if (!rampartConfigEl) return

    const rampartConfig = JSON.parse(rampartConfigEl.dataset.config)
    const render = (firstName) => {
      const cookies = cookieParser.parse(document.cookie)
      const hasLoggedInSession = cookies.sid
      const isProduction = serviceLocator.serverEnv === 'production'

      // this is here to ensure the user is in the same state between auth0 and our platform
      // if for some reason auth0 think they are logged in, but our system doesn't, trigger page refresh which should log them in
      // if for some reason auth0 thinks they are logged out, but our system thinks they are logged in, log them our of our system
      if (isProduction) {
        if (!hasLoggedInSession && firstName) {
          return window.location.reload()
        } else if (hasLoggedInSession && !firstName) {
          window.location = '/auth/logout?returnTo=' + window.location.href
          return
        }
      }

      const loginHandler = (e) => {
        e.preventDefault()
        window.rampart.login(window.location.href)
      }
      const subscribeHandler = (e) => {
        e.preventDefault()
        window.open(serviceLocator.subscribeUrl)
      }
      const logoutHandler = (e) => {
        e.preventDefault()
        window.rampart.ssoLogout(window.location.href)
      }

      const theme = document.querySelector('body').dataset.theme

      document.querySelectorAll('.js-subscriber-benefits-widget').forEach(appNode => {
        const props = JSON.parse(appNode.dataset.props)
        ReactDOM.render(
          <ThemeContext.Provider value={theme}>
            <TextWithImageCta {...props} loginOnClick={loginHandler} />
          </ThemeContext.Provider>,
          appNode
        )
      })

      document.querySelectorAll('.js-not-logged-in-button-area').forEach(appNode => {
        const block = appNode.dataset.block === 'true'
        ReactDOM.render(
          <ThemeContext.Provider value={theme}>
            <NotLoggedInButtonArea block={block} loginOnClick={loginHandler} subscribeOnClick={subscribeHandler} />
          </ThemeContext.Provider>,
          appNode
        )
      })

      document.querySelectorAll('.js-not-eligible-button-area').forEach(appNode => {
        const block = appNode.dataset.block === 'true'
        ReactDOM.render(
          <ThemeContext.Provider value={theme}>
            <NotEligibleButtonArea block={block} subscribeOnClick={subscribeHandler} />
          </ThemeContext.Provider>,
          appNode
        )
      })

      document.querySelectorAll('.js-login-subscribe').forEach(appNode => {
        const props = JSON.parse(appNode.dataset.props)
        ReactDOM.render(
          <ThemeContext.Provider value={theme}>
            <LoginSubscribe loginOnClick={loginHandler} subscribeOnClick={subscribeHandler} {...props} />
          </ThemeContext.Provider>,
          appNode
        )
      })

      document.querySelectorAll('.js-hero').forEach(appNode => {
        const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
        ReactDOM.render(
          <ThemeContext.Provider value={theme}>
            <Hero {...props} loginOnClick={loginHandler} subscribeOnClick={subscribeHandler} isRehydrated />
          </ThemeContext.Provider>,
          appNode
        )
      })

      document.querySelectorAll('.js-user-menu').forEach(appNode => {
        ReactDOM.render(
          <ThemeContext.Provider value={theme}>
            <UserActions
              firstName={firstName || ''}
              handleLogin={loginHandler}
              handleSubscribe={subscribeHandler}
              handleLogout={logoutHandler}
              paperName={appNode.dataset.paperName}
              paperUrl={appNode.dataset.paperUrl}
              instances={JSON.parse(appNode.dataset.instances)}
              instancePath={serviceLocator.instancePath}
            />
          </ThemeContext.Provider>,
          appNode
        )
      })
    }

    const loadRampart = () => {
      return new Promise(resolve => {
        const tag = document.createElement('script')
        tag.src = rampartConfig.scriptUrl
        tag.async = true
        tag.onload = () => {
          window.rampart = new window.Rampart({
            site: rampartConfig.site,
            callbackUri: rampartConfig.callbackUri,
            logoutUri: rampartConfig.logoutUri
          })
          resolve()
        }
        const fst = document.getElementsByTagName('script')[0]
        fst.parentNode.insertBefore(tag, fst)
      })
    }

    loadRampart()
      .then(() => {
        window.rampart.getLoginStatus()
          .then(payload => {
            render(payload.name)
          })
          .catch(() => {
            render()
          })
      })
  })
}
