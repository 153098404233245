import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import withTheme from '../Theme/withTheme'
import Button from '../Primitive/Button'
import Content from '../Primitive/Content'
import Prose from '../Primitive/Prose'
import ShrinkWrap, { ShrinkWrapItem } from '../Primitive/ShrinkWrap'
import { IconButton } from '../Primitive/IconButton'

class CookiePolicy extends PureComponent {
  constructor (props) {
    super(props)

    this.handleDismiss = this.handleDismiss.bind(this)

    this.state = {
      visible: false
    }
  }

  handleDismiss () {
    localStorage.setItem('cookieNoticeDismissed', true)

    this.setState({
      visible: false
    })
  }

  componentDidMount () {
    const { visible } = this.state
    const cookieNoticeDismissed = JSON.parse(localStorage.getItem('cookieNoticeDismissed'))

    if (!visible && !cookieNoticeDismissed) {
      setTimeout(() => {
        this.setState({
          visible: true
        })
      }, 1000)
    }
  }

  render () {
    const { theme } = this.props
    const { visible } = this.state
    return (
      <div className="js-cookie-policy">
        <Content center className="js-cookie-policy">
          <div className={classNames(
            'CookiePolicy',
            theme && `CookiePolicy-${theme}`,
            visible && 'CookiePolicy-is-visible'
          )}>
            <div className="CookiePolicyInner">
              <h3 className="CookiePolicyHeading">Cookies</h3>
              <ShrinkWrap vAlign="top" className="CookiePolicyShrinkWrap">
                <ShrinkWrapItem>
                  <Prose>
                    <p className="CookiePolicyCopy">Our site uses cookies to improve your experience and deliver personalised advertising. By continuing to use this site you are agreeing to our use of cookies. To find our more, including how to change your settings, see our <a href="https://preferences.news.com.au/cookies">Cookie Policy</a>.</p>
                  </Prose>
                </ShrinkWrapItem>
                <ShrinkWrapItem shrink className="CookiePolicyButtonWrapper">
                  <Button className="CookiePolicyDismiss" primary center onClick={this.handleDismiss}>OK</Button>
                </ShrinkWrapItem>
              </ShrinkWrap>
              <IconButton a11yText="Dismiss" icon="CrossSmall" onClick={this.handleDismiss} className="CookiePolicyClose" />
            </div>
          </div>
        </Content>
      </div>
    )
  }
}

CookiePolicy.propTypes = {
  theme: PropTypes.string
}

export default withTheme(CookiePolicy)
