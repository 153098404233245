import React, { PureComponent } from 'react'
import CompetitionWinner from '../../../site/widgets/competition-winners/component/CompetitionWinners'
import PropTypes from 'prop-types'
import getOffers from './offers-getter'

class CompetitionWinnerComponent extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      offers: props.originalOffers,
      showLoadMore: props.hasMore,
      page: 0
    }

    this.loadMoreClicked = this.loadMoreClicked.bind(this)
  }

  async loadMoreClicked (e) {
    const { offers, page } = this.state
    const { totalPer, base } = this.props
    const result = await getOffers(base, page + 1, totalPer)
    const newOffers = offers.concat(result.offers)

    this.setState({
      showLoadMore: !!result.offers.length && (newOffers.length < result.total),
      offers: newOffers,
      page: page + 1
    })
  }

  render () {
    const { offers, showLoadMore } = this.state
    return <CompetitionWinner
      offers={offers}
      showLoadMore={showLoadMore}
      loadMoreClicked={this.loadMoreClicked}
      {...this.props} />
  }
}

CompetitionWinnerComponent.propTypes = {
  totalPer: PropTypes.number,
  base: PropTypes.string,
  hasMore: PropTypes.bool,
  originalOffers: PropTypes.array
}

export default CompetitionWinnerComponent
