import React, { PureComponent, Fragment } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withTheme from '../Theme/withTheme'

import Button from '../Primitive/Button'
import Menu from '../Primitive/Menu'
import IconButton from '../Primitive/IconButton'

import { widthMax, tablet } from '../Primitive/Media'

class UserActions extends PureComponent {
  constructor () {
    super()
    this.state = {
      menuOpen: false
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    if (!this.state.menuOpen) {
      window.messageBus.emit('userActionsOpen')
      this.menu.classList.add('is-open')
      document.body.classList.add('has-locked-scrolling')
    } else {
      this.menu.classList.remove('is-open')
      document.body.classList.remove('has-locked-scrolling')
    }
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  componentDidMount () {
    window.messageBus.on('navigationOpen', () => {
      this.setState({ menuOpen: false })
      this.menu.classList.remove('is-open')
    })

    const breakpoint = window.matchMedia(widthMax(tablet))
    const query = e => handleMenu(e)
    breakpoint.addListener(query)

    const handleMenu = breakpoint => {
      document.body.classList.remove('has-locked-scrolling')
      if (breakpoint.matches) {
        this.menu.classList.remove('is-open')
        this.setState({ menuOpen: false })
      } else {
        this.menu.classList.add('is-open')
        this.setState({ menuOpen: true })
      }
    }

    handleMenu(breakpoint)
  }

  render () {
    const { handleSubscribe, handleLogin, handleMyAccount, handleLogout, firstName } = this.props

    return (
      <div
        className={classNames(
          'UserActionsWrapper',
          firstName && 'logged-in')}
        ref={(menu) => { this.menu = menu }}
      >
        <IconButton icon={this.state.menuOpen ? 'Close' : 'Avatar'} className="UserActionsMobileToggle" onClick={this.toggle} />
        <div className="UserActionsMobileMenuWrapper">
          {firstName ? (
            <Menu icon="Avatar" label={`Hi ${firstName}`}>
              {handleMyAccount && <a className="UserActionsLink" href="#" onClick={handleMyAccount}>My Account</a>}
              <a className="UserActionsLink" href="/my-rewards">My Rewards</a>
              <Button block outline onClick={handleLogout}>Logout</Button>
            </Menu>
          ) : (
            <Fragment>
              <Button href="#" onClick={handleSubscribe} secondary className="UserActionsButton">
                Subscribe
              </Button>
              <Button href="#" onClick={handleLogin} login className="UserActionsButton">
                Log in
              </Button>
            </Fragment>
          )}
        </div>
      </div>
    )
  }
}

UserActions.propTypes = {
  firstName: PropTypes.string,
  handleLogin: PropTypes.func,
  handleSubscribe: PropTypes.func,
  handleMyAccount: PropTypes.func,
  handleLogout: PropTypes.func
}

export default withTheme(UserActions)
