import React, { PureComponent } from 'react'
import SortableOffers from '../../../site/layout/component/SortableOffers'
import PropTypes from 'prop-types'
import getOffers from './offers-getter'

class SortableOffersComponent extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      offers: props.originalOffers
    }

    this.sortChanged = this.sortChanged.bind(this)
  }

  async sortChanged (sortType) {
    window.history.replaceState(null, null, `?sort=${encodeURIComponent(sortType)}`)
    const offers = await getOffers(this.props.base, this.props, sortType)
    this.setState({ offers })
  }

  render () {
    const { offers } = this.state
    return <SortableOffers offers={offers} sortChanged={this.sortChanged} {...this.props} emphasisedWordLocation={this.props.emphasised} />
  }
}

SortableOffersComponent.propTypes = {
  base: PropTypes.string,
  originalOffers: PropTypes.array,
  emphasised: PropTypes.string
}

export default SortableOffersComponent
