import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withTheme from '../../component/Theme/withTheme'
import classNames from 'classnames'
import Masthead from '../Masthead'
import MastheadList from '../MastheadList'
import Content from '../Primitive/Content'
import Icon from '../Primitive/Icon'

class MastheadSelector extends PureComponent {
  render () {
    const { theme, instances = [], hasCta, isList } = this.props
    return (
      <div className="MastheadSelectorContainer">
        {!isList ? (
          <Content center gutter>
            <div className={classNames(
              'MastheadSelector',
              theme && `MastheadSelector-${theme}`)
            }>
              {instances.map((instance, i) => (
                <Masthead
                  key={`masthead-${i}`}
                  link={instance.subdomain.indexOf('/') === 0 ? instance.subdomain : '//' + instance.subdomain}
                  logo={instance._logo.dark}
                  backgroundImage={instance._backgroundImage}
                />
              ))}
              { hasCta &&
                <a href="#subscribe" className="MastheadCta">
                  <p>Not a member?</p>
                  <Icon type="ChevronDown" height={20} width={20} />
                </a>
              }
            </div>
          </Content>
        ) : (
          <ul className={classNames(
            'MastheadSelector',
            'MastheadSelectorList',
            theme && `MastheadSelector-${theme} MastheadSelectorList-${theme}`)
          }>
            {instances.map((instance, i) => (
              <MastheadList
                key={`masthead-${i}`}
                link={instance.subscribeUrl}
                logo={instance._logo.dark}
                backgroundImage={instance._backgroundImage}
                name={instance.name}
              />
            ))}
          </ul>
        )}
      </div>
    )
  }
}

MastheadSelector.propTypes = {
  theme: PropTypes.string,
  instances: PropTypes.array,
  hasCta: PropTypes.bool,
  isList: PropTypes.bool
}

export default withTheme(MastheadSelector)
