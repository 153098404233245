const mobile = '300px'
const mobileLandscape = '400px'
const tablet = '550px'
const tabletLandscape = '800px'
const desktop = '960px'
const desktopMedium = '1280px'
const desktopLarge = '1600px'

const widthMin = width => `(min-width: ${width})`
const widthMax = width => `(max-width: ${width})`

export {
  mobile,
  mobileLandscape,
  tablet,
  tabletLandscape,
  desktop,
  desktopMedium,
  desktopLarge,
  widthMin,
  widthMax
}
