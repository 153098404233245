export default () => {
  // set cookie if not on home page,
  const sectionPath = document.body.dataset.fullPath
  sectionPath === '/' ? document.cookie = 'smoothscroll=false' : document.cookie = 'smoothscroll=true'

  // if cookie exists, add class to HTML element
  if (document.cookie.split(';').filter((item) => item.includes('smoothscroll=true')).length) {
    document.documentElement.classList.add('smooth-scroll')

    // Replaces "jumping" to anchor with smooth scroll, on initial page load
    const pageContentEl = document.getElementById('page-content')
    pageContentEl && pageContentEl.scrollIntoView({
      behavior: 'smooth',
      block: 'start'
    })
  }
}
