import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import stripTags from 'striptags'
import withTheme from '../../../layout/component/Theme/withTheme'
import Button from '../../../layout/component/Primitive/Button'
import Icon from '../../../layout/component/Primitive/Icon'
import { Grid, GridItem } from '../../../layout/component/Primitive/Grid'

class Competition extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showingAll: props.showAll
    }
  }

  render () {
    const { displayHeadline, winners, runnerUps, category, imageUrl, theme, showAll, grid } = this.props
    const winLabel = winners.length > 1 ? 'Winners' : 'Winner'
    const runnerUpLabel = runnerUps && runnerUps.length > 1 ? 'Runners up' : 'Runner Up'
    const winnersShown = this.state.showingAll ? winners : winners.slice(0, 10)

    const AusWinners = () => (
      <div className="SummaryInner SummaryInnerHorizontal">
        <span className="SummaryCategory">{category}</span>
        <h2 className="SummaryHeadline" dangerouslySetInnerHTML={{ __html: displayHeadline }} />
        <div className="SummaryWinners">
          <b>{winLabel}: </b>
          {winnersShown.join(', ')}
        </div>
        <div className="SummaryWinners">
          {winners.length > 10 && !showAll && (
            <Button textButton onClick={() => this.setState({ showingAll: !this.state.showingAll })}>
              <span>{this.state.showingAll ? 'Show less winners' : 'Show all the winners'}</span>
              <Icon width={10} height={6} type={this.state.showingAll ? 'ChevronUp' : 'ChevronDown'} />
            </Button>
          )}
        </div>
      </div>
    )

    const RewardsWinners = () => (
      <div className="SummaryInner">
        <h2 className="SummaryHeadline" dangerouslySetInnerHTML={{ __html: displayHeadline }} />
        <div className="SummaryCongratulations">
          <Icon type="Rosette" />
          <span>Congratulations:</span>
        </div>
        <p className="SummaryWinners">
          { runnerUps && runnerUps.length > 0 && <b>{winLabel}: </b> }
          {winners.join(', ')}
        </p>
        {runnerUps && runnerUps.length > 0
          ? <div className="SummaryWinners">
            <b>{runnerUpLabel}: </b>
            {runnerUps.join(', ')}
          </div> : null}
      </div>
    )

    const winnersMap = {
      'aus': <AusWinners />,
      'rewards': <RewardsWinners />
    }

    const imageSize = grid ? [] : [ 'tablet-landscape-one-third' ]
    const contentSize = grid ? [] : [ 'tablet-landscape-two-thirds' ]

    return (
      <div className={classNames(
        'Summary-competition',
        theme && `Summary-${theme}`
      )}>
        <Grid gutterless>
          <GridItem sizes={imageSize}>
            <img className="SummaryImage" src={imageUrl} alt={stripTags(displayHeadline)} />
          </GridItem>
          <GridItem sizes={contentSize}>
            {winnersMap[theme] || <div />}
          </GridItem>
        </Grid>
      </div>
    )
  }
}

Competition.propTypes = {
  displayHeadline: PropTypes.string,
  imageUrl: PropTypes.string,
  category: PropTypes.string,
  winners: PropTypes.array,
  showAll: PropTypes.bool,
  grid: PropTypes.bool,
  theme: PropTypes.string,
  runnerUps: PropTypes.array
}

export default withTheme(Competition)
