import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import Content from '../Content'
import Icon from '../Icon'
import Carousel from '../Carousel'

class QuoteRewards extends PureComponent {
  render () {
    const { quotes } = this.props
    return (
      <div className="Quote Quote-rewards">
        <Content width="medium" center className="QuoteInner">
          <Icon type="SpeechMark" className="QuoteIcon" width={25} />
          {quotes.length > 1 ? (
            <Carousel autoplay>
              {quotes.map((item, i) => (
                <div key={`quote-${i}`} className="QuoteItem">
                  <blockquote>
                    <p className="QuoteText">{item.quote}</p>
                    <cite className="QuoteAuthor">{item.author}</cite>
                  </blockquote>
                </div>
              ))}
            </Carousel>
          ) : (
            <div className="QuoteItem">
              <blockquote>
                <p className="QuoteText">{quotes[0].quote}</p>
                <cite className="QuoteAuthor">{quotes[0].author}</cite>
              </blockquote>
            </div>
          )}
          <Icon type="SpeechMark" className="QuoteIcon" width={25} />
        </Content>
      </div>
    )
  }
}

QuoteRewards.propTypes = {
  quotes: PropTypes.array.isRequired
}

export default QuoteRewards
