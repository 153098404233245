import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import withTheme from '../../component/Theme/withTheme'
import classNames from 'classnames'

class Masthead extends PureComponent {
  render () {
    const { link, backgroundImage, logo, theme } = this.props

    return (
      <Fragment>
        <a
          href={link}
          className={classNames('Masthead', theme && `Masthead-${theme}`)}
          style={{ backgroundImage: 'url(' + backgroundImage + ')' }}
        >
          <img src={logo} alt="Logo" />
        </a>
      </Fragment>
    )
  }
}

Masthead.propTypes = {
  theme: PropTypes.string,
  backgroundImage: PropTypes.string,
  logo: PropTypes.string,
  link: PropTypes.string
}

export default withTheme(Masthead)
