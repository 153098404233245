import React from 'react'
import PropTypes from 'prop-types'
import Competition from './Competition'
import Title from '../../../layout/component/Title'
import Content from '../../../layout/component/Primitive/Content'
import LoadMore from '../../../layout/component/Primitive/LoadMore'

const CompetitionWinners = (props) => {
  const { offers, loadMoreClicked, showLoadMore } = props
  return (
    <div>
      <Content center gutter>
        {offers.length > 0 ? offers.map((offer, i) => (
          <Competition key={i} {...offer} />
        )) : (
          <Title
            title="No competition winners found"
            subtitle="There hasn't been any competition winners yet. Please try again later."
          />
        )}
      </Content>
      {showLoadMore && <LoadMore clicked={loadMoreClicked} />}
    </div>
  )
}

CompetitionWinners.propTypes = {
  offers: PropTypes.array,
  showLoadMore: PropTypes.bool,
  loadMoreClicked: PropTypes.func
}

export default CompetitionWinners
