import React from 'react'
import PropTypes from 'prop-types'
import KeyFacts from '../../../site/layout/component/KeyFacts'

class KeyFactsContainer extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      hasRedeemed: props.stateData.hasRedeemed
    }
  }

  componentDidMount () {
    window.messageBus.on('redemptionRedeemed', () => {
      this.setState({ hasRedeemed: true })
    })
  }

  redemptionClicked () {
    const { redemptionType } = this.props.ctaData
    const redemptionTypesNotRevealingOnClick = [ 'purchase', 'competition' ]
    if (!redemptionTypesNotRevealingOnClick.includes(redemptionType)) {
      window.messageBus.emit('redemptionClicked')
    }
    window.fbq && window.fbq('track', 'Purchase', { value: 0, currency: 'AUD' })
  }

  render () {
    const { stateData } = this.props
    stateData.hasRedeemed = this.state.hasRedeemed
    return <KeyFacts
      items={this.props.parsedKeyFacts}
      {...this.props} {...this.state} stateData={stateData}
      redemptionClicked={() => this.redemptionClicked()} />
  }
}

KeyFactsContainer.propTypes = {
  ctaData: PropTypes.object,
  stateData: PropTypes.object,
  parsedKeyFacts: PropTypes.array
}

export default KeyFactsContainer
