const determineOverlaySize = (width, totalOffers) => {
  const widthToClass = {
    'One': 'SummaryOverlayExtraLarge',
    'Two': 'SummaryOverlayLarge'
  }

  if (width !== 'Auto') {
    return widthToClass[width]
  }

  if (totalOffers === 1) {
    return widthToClass['One']
  }

  if (totalOffers === 2 || totalOffers === 4) {
    return widthToClass['Two']
  }
}

export default determineOverlaySize
