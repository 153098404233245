import React, { PureComponent } from 'react'
import moment from 'moment-timezone'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Spring, animated } from 'react-spring'

import { Grid, GridItem } from '../../../layout/component/Primitive/Grid'
import ResendEmail from '../../offer-layout/redemption-area/component/ResendEmail'
import RedemptionSummary from '../../../layout/component/RedemptionSummary'
import Icon from '../../../layout/component/Primitive/Icon'

class RedemptionHistoryItem extends PureComponent {
  constructor () {
    super()

    this.state = {
      expanded: false
    }

    this.handleToggle = this.handleToggle.bind(this)
  }

  handleToggle () {
    this.setState({ expanded: !this.state.expanded })
  }

  render () {
    const { offer } = this.props
    const Summary = () => (
      <div className="SummaryRewards" onClick={this.handleToggle}>
        <Grid flex gutterless>
          <GridItem sizes={[ 'tablet-landscape-one-third' ]}>
            <img
              className="SummaryImage"
              src={offer.image.url}
              alt="Headline"
            />
          </GridItem>
          <GridItem sizes={[ 'tablet-landscape-two-thirds' ]}>
            <div className="SummaryInner">
              {(offer.displayHeadline && (
                <h2
                  className="SummaryHeadline"
                  dangerouslySetInnerHTML={{ __html: offer.displayHeadline }}
                />
              )) || (
                <h2 className="SummaryHeadline">{offer.headline}</h2>
              )}
              <p className="SummaryExtraInfo">{offer.extraInfo}</p>
              <p className="SummaryRedeemed">
                Redeemed on {moment(offer.redeemedDate).format('MMM Do, YYYY')}
              </p>
              <span className="SummaryToggle">
                {this.state.expanded ? 'Hide Details' : 'Redemption Details'}
                <Icon type="ChevronDownAlt" width={10} />
              </span>
            </div>
          </GridItem>
        </Grid>
      </div>
    )
    return (
      <div
        className={classNames(
          'SummaryWrapper',
          'SummaryRewardsWrapper',
          'SummaryRewardsWrapper--history',
          this.state.expanded && 'SummaryRewardsWrapper-open'
        )}
      >
        <div className="Summary-redeemed Summary-rewards">
          <Summary />
          <Spring
            native
            from={{ height: 0 }}
            to={{ height: this.state.expanded ? 'auto' : 0 }}
          >
            {props => (
              <animated.div className="SummaryRewardsDropdown" style={props}>
                <RedemptionSummary
                  {...offer.thankYouData}
                  link={offer.__fullUrlPath}
                >
                  {offer.isPurchase && <ResendEmail {...this.props} />}
                </RedemptionSummary>
              </animated.div>
            )}
          </Spring>
        </div>
      </div>
    )
  }
}

RedemptionHistoryItem.propTypes = {
  offer: PropTypes.object
}

export default RedemptionHistoryItem
