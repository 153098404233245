import React from 'react'
import PropTypes from 'prop-types'
import stripTags from 'striptags'
import Content from '../../../../layout/component/Primitive/Content'
import { Grid, GridItem } from '../../../../layout/component/Primitive/Grid'
import FieldTemplate from '../../../../layout/component/Primitive/FieldTemplate'

const processData = (data) => {
  if (typeof data === 'object') {
    return Object.keys(data).map(key => (
      <p key={key}>{data[key]}</p>
    ))
  }

  return <p>{data}</p>
}

const FormOverview = (props) => {
  const { data = {}, formData } = props
  const { questions } = formData || {}

  const widthClasses = {
    'full': [ 'one-whole' ],
    'half': [ 'tablet-one-half' ],
    'quarter': [ 'tablet-one half', 'desktop-one-quarter' ]
  }

  return (
    <Content center>
      <Grid>
        {questions && questions.map((question, i) => {
          const questionLabel = stripTags(question.question)
          return (
            <GridItem key={`form-builder-${i}`} sizes={widthClasses[question.width]}>
              <FieldTemplate
                label={questionLabel}
              >
                <div>{processData(data[`question-${i}`]) || ''}</div>
              </FieldTemplate>
            </GridItem>
          )
        })}
      </Grid>
    </Content>
  )
}

FormOverview.propTypes = {
  data: PropTypes.object,
  formData: PropTypes.object
}

export default FormOverview
