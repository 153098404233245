import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import withTheme from '../component/Theme/withTheme'

import IconButton from '../component/Primitive/IconButton'
import Content from '../component/Primitive/Content'
import Search from '../component/Search'

import { widthMax, tablet } from '../component/Primitive/Media'

class NavigationAus extends PureComponent {
  constructor () {
    super()
    this.state = {
      menuOpen: false
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    if (!this.state.menuOpen) {
      window.messageBus.emit('navigationOpen')
      this.navMenu.classList.add('is-open')
      document.body.classList.add('has-locked-scrolling')
    } else {
      this.navMenu.classList.remove('is-open')
      document.body.classList.remove('has-locked-scrolling')
    }
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  componentDidMount () {
    window.messageBus.on('userActionsOpen', () => {
      this.setState({ menuOpen: false })
      this.navMenu.classList.remove('is-open')
    })

    const breakpoint = window.matchMedia(widthMax(tablet))
    const query = e => handleMenu(e)
    breakpoint.addListener(query)

    const handleMenu = breakpoint => {
      document.body.classList.remove('has-locked-scrolling')
      if (breakpoint.matches) {
        this.navMenu.classList.remove('is-open')
        this.setState({ menuOpen: false })
      } else {
        this.navMenu.classList.add('is-open')
        this.setState({ menuOpen: true })
      }
    }

    handleMenu(breakpoint)
  }

  render () {
    const { theme, items, currentSection, instance } = this.props

    return (
      <div
        className={classNames(
          'Navigation',
          theme && `Navigation-${theme}`
        )}
        ref={(navMenu) => { this.navMenu = navMenu }}
      >
        <Content center gutter>
          <IconButton icon={this.state.menuOpen ? 'Close' : 'Hamburger'} className="NavigationToggle" onClick={this.toggle} />
          <div className="NavigationItems">
            <a href="/" aria-label="Home">
              <img className="NavigationLogo" src={instance && instance._logo && instance._logo.light} alt="Logo" />
            </a>
            <div className="NavigationLinks">
              {items && items.map((item, i) => (
                <a
                  key={i}
                  className={classNames(
                    'NavigationItem',
                    currentSection === item.url && 'active'
                  )}
                  href={item.url}
                >
                  {item.text}
                </a>
              ))}
            </div>
            <Search />
          </div>
        </Content>
      </div>
    )
  }
}

NavigationAus.propTypes = {
  theme: PropTypes.string,
  currentSection: PropTypes.string,
  instance: PropTypes.object,
  items: PropTypes.arrayOf(PropTypes.object)
}

export default withTheme(NavigationAus)
