import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'
import Button from '../Button'
import Icon from '../Icon'
import { widthMax, tablet } from '../Media'

class Menu extends PureComponent {
  constructor () {
    super()

    this.state = {
      isOpen: false
    }

    this.toggle = this.toggle.bind(this)
    this.close = this.toggle.bind(this)
    this.breakpoint = typeof window !== 'undefined' && window.matchMedia(widthMax(tablet))
  }

  componentDidMount () {
    const query = () => {
      this.setState({ isOpen: this.props.theme === 'aus' && this.breakpoint.matches })
    }

    this.setState({ isOpen: this.props.theme === 'aus' && this.breakpoint.matches })
    this.breakpoint.addListener(query)
  }

  toggle () {
    if (this.props.theme === 'aus') {
      !this.breakpoint.matches && this.setState({ isOpen: !this.state.isOpen })
    } else {
      this.setState({ isOpen: !this.state.isOpen })
    }
  }

  close () {
    this.setState({ isOpen: false })
  }

  render () {
    const { theme, label, icon, children, className } = this.props

    const setAltChevron = () => {
      return theme === 'rewards' ? 'Alt' : ''
    }

    return (
      <div
        className={classNames(
          'Menu',
          `Menu-${theme}`,
          className
        )}
      >
        {this.state.isOpen && <div className="MenuOverlay" onClick={this.close} />}
        <Button
          className={classNames(
            'MenuButton',
            this.state.isOpen && 'is-active'
          )}
          onClick={this.toggle}
        >
          {icon && <Icon className="MenuIcon" type={icon} />}
          <span>{label}</span>
          <Icon
            width={9}
            height={6}
            type={this.state.isOpen
              ? `ChevronUp${setAltChevron()}`
              : `ChevronDown${setAltChevron()}`
            }
          />
        </Button>
        {this.state.isOpen && (
          <div className="MenuItems">
            {children}
          </div>
        )}
      </div>
    )
  }
}

Menu.displayName = 'Menu'

Menu.propTypes = {
  theme: PropTypes.string,
  label: PropTypes.string,
  icon: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.node
}

export default withTheme(Menu)
