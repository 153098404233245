import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import JsBarcode from 'jsbarcode'
import withTheme from '../Theme/withTheme'
import Prose from '../Primitive/Prose'
import Button from '../Primitive/Button'
import IconButton from '../Primitive/IconButton'
import Modal from '../Primitive/Modal'
import { Grid, GridItem } from '../Primitive/Grid'

class RedemptionSummary extends PureComponent {
  constructor() {
    super()
    this.toggleModalState = this.toggleModalState.bind(this)
    this.state = {
      modalIsOpen: false
    }
  }

  toggleModalState() {
    const currentState = this.state.modalIsOpen
    this.setState({ modalIsOpen: !currentState })
  }

  componentDidMount() {
    if (typeof document === 'undefined') return
    const el = document.getElementById('unique-barcode')
    if (el) {
      const code = el.dataset.code
      JsBarcode(el, code)
    }
  }

  handleAddToAppleWallet(instancePath, offerId) {
    return async () => {
      const response = await fetch(
        `${instancePath}api/redemption/add-to-wallet/ios/${offerId}${window.location.search}`,
        {
          method: 'POST'
        }
      )
      if (response.ok) {
        const blob = await response.blob()

        const urlLink = window.URL.createObjectURL(blob)
        var a = document.createElement('a')
        a.href = urlLink
        a.download = 'offer.pkpass'
        document.body.appendChild(a)
        a.click()
        a.remove()
      } else {
        this.toggleModalState()
      }
    }
  }

  handleAddToAndroidWallet(instancePath, offerId) {
    return async () => {
      const response = await fetch(
        `${instancePath}api/redemption/add-to-wallet/android/${offerId}${window.location.search}`,
        { method: 'POST' }
      )
      if (response.ok) {
        const androidPass = await response.json()
        const redirectLink = `https://pay.google.com/gp/v/save/${androidPass.token}`
        window.location = redirectLink
      } else {
        this.toggleModalState()
      }
    }
  }

  render() {
    const {
      theme,
      title,
      subtitle,
      html,
      ctaText,
      secondaryCtaText,
      ctaLink,
      ctaOpensInNewWindow,
      children,
      link,
      instancePath,
      offerId,
      showMobileWallet
    } = this.props

    return (
      <Fragment>
        <div
          className={classNames(
            'RedemptionSummary',
            theme && `RedemptionSummary-${theme}`
          )}
        >
          <h2 className="RedemptionSummaryTitle">
            <span className="RedemptionSummaryTitleEmphasised">{title}</span>
            {subtitle}
          </h2>
          {html && <Prose className="RedemptionSummaryCopy" html={html} />}
          {ctaLink && (secondaryCtaText || ctaText) && (
            <Grid theme={theme} gutterless>
              <GridItem sizes={['tablet-landscape-one-third']}>
                <Button
                  uppercase
                  target={ctaOpensInNewWindow ? '_blank' : ''}
                  primary
                  href={ctaLink}
                >
                  {secondaryCtaText || ctaText}
                </Button>
              </GridItem>
              {showMobileWallet && (
                <>
                  <GridItem sizes={['tablet-one-whole']}>
                    <Button
                      onClick={this.handleAddToAppleWallet(
                        instancePath,
                        offerId
                      )}
                      theme={theme}
                      className="noHorizontalPadding"
                    >
                      <img src="/assets/img/content/add-to-apple-wallet.png" />
                    </Button>
                  </GridItem>
                  <GridItem sizes={['tablet-one-whole']}>
                    <IconButton
                      onClick={this.handleAddToAndroidWallet(
                        instancePath,
                        offerId
                      )}
                      theme={theme}
                      icon="AndroidWallet"
                      small
                      className="noHorizontalPadding addToAndroidWallet"
                    />
                  </GridItem>
                </>
              )}
            </Grid>
          )}
          {children}
        </div>
        {link && (
          <p className="RedemptionSummaryLink">
            <a href={link}>Visit the page</a> for full details of this Reward.
          </p>
        )}
        {this.state.modalIsOpen && (
          <Modal handleClose={this.toggleModalState} theme={theme}>
            <h1 className="ModalHeading">
              Sorry we can’t add your mobile pass at this time
            </h1>
          </Modal>
        )}
      </Fragment>
    )
  }
}

RedemptionSummary.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  ctaText: PropTypes.string,
  secondaryCtaText: PropTypes.string,
  ctaLink: PropTypes.string,
  html: PropTypes.string,
  ctaOpensInNewWindow: PropTypes.bool,
  showMobileWallet: PropTypes.bool,
  link: PropTypes.string,
  children: PropTypes.node,
  instancePath: PropTypes.string,
  offerId: PropTypes.string
}

export default withTheme(RedemptionSummary)
