import React from 'react'
import PropTypes from 'prop-types'
import BaseFormBuilder from '../form-builder/BaseFormBuilder'
import BasicRedemptionArea from '../../../site/widgets/offer-layout/redemption-area/component/BasicRedemptionArea'
import performRedemption from './redemption-submitter'

class RedemptionAreaContainer extends BaseFormBuilder {
  constructor(props) {
    super(props)
    this.loadRedemptionInfo = this.loadRedemptionInfo.bind(this)
    this.doLoadRedemptionInfo = this.doLoadRedemptionInfo.bind(this)
    this.submitForm = this.submitForm.bind(this)
    this.state = {
      ...this.state,
      redemptionInfoLoaded: this.props.redemptionInfoLoaded
    }

    if (this.props.dataCapture) {
      this.apiRoute = 'reader-comp/submit'
      this.errorMessage = 'submitting form'
    } else {
      this.apiRoute = 'redemption'
      this.errorMessage = 'redeeming basic offer'
    }
  }

  componentDidMount() {
    window.messageBus.on('redemptionClicked', () => {
      if (!this.props.formData) this.loadRedemptionInfo()
    })
  }

  loadRedemptionInfo(e) {
    if (e) e.preventDefault()
    this.setState({ waiting: true })
    this.doLoadRedemptionInfo()
  }

  async doLoadRedemptionInfo() {
    const data = {
      formId: this.props.formData ? this.props.formData._id : null,
      formData: this.state.data
    }

    const { instancePath, offerId, formData } = this.props
    const result = await performRedemption(
      data,
      instancePath,
      offerId,
      this.apiRoute,
      this.errorMessage,
      formData
    )
    this.setState(result)
  }

  submitForm(e) {
    e.preventDefault()
    this.props.schema.validate(this.state.data, (err, errors) => {
      if (err) return alert(err)
      if (Object.keys(errors).length > 0)
        return this.setState({ error: errors })
      this.setState({ waiting: true, error: {} })
      this.doLoadRedemptionInfo()
    })
  }

  render() {
    return (
      <BasicRedemptionArea
        loadRedemptionInfo={this.loadRedemptionInfo}
        submitForm={this.submitForm}
        onChange={this.onChange}
        {...this.props}
        {...this.state}
      />
    )
  }
}

RedemptionAreaContainer.propTypes = {
  instancePath: PropTypes.string,
  offerId: PropTypes.string,
  dataCapture: PropTypes.bool,
  showMobileWallet: PropTypes.bool
}

export default RedemptionAreaContainer
