import React from 'react'
import ReactDOM from 'react-dom'
import TextWithImageCtaRewards from '../../../../components/site/layout/component/Primitive/TextWithImageCta/TextWithImageCtaRewards'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'
const debug = require('debug')('subscriberBenefits')
export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-subscriber-benefits').forEach(appNode => {
      const props = JSON.parse(appNode.getAttribute('data-props') || '{}')
      ReactDOM.render(
        <ThemeContext.Provider value={'rewards'}>
          <TextWithImageCtaRewards {...props} />
        </ThemeContext.Provider>,
        appNode
      )
    })
  })
}
