import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withTheme from '../../../component/Theme/withTheme'
import QuoteAus from './QuoteAus'
import QuoteRewards from './QuoteRewards'

class Quote extends PureComponent {
  render () {
    const { theme } = this.props
    const Component = {
      'aus': <QuoteAus {...this.props} />,
      'rewards': <QuoteRewards {...this.props} />
    }

    return Component[theme]
  }
}

Quote.propTypes = {
  theme: PropTypes.string
}

export default withTheme(Quote)
