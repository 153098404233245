import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

class Content extends PureComponent {
  render () {
    const { children, center, gutter, width, className } = this.props
    return (
      <div className={classNames(
        'Content',
        center && 'center',
        gutter && 'gutter',
        width && width,
        className
      )}>
        {children}
      </div>
    )
  }
}

Content.propTypes = {
  children: PropTypes.node,
  center: PropTypes.bool,
  className: PropTypes.string,
  width: PropTypes.oneOf([ 'small', 'wide', 'medium' ]),
  gutter: PropTypes.bool
}

export default Content
