import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withTheme from '../../../component/Theme/withTheme'
import LoadingSpinnerAus from './LoadingSpinnerAus'
import LoadingSpinnerRewards from './LoadingSpinnerRewards'

class LoadingSpinner extends PureComponent {
  render () {
    const { theme } = this.props
    const Component = {
      'aus': <LoadingSpinnerAus {...this.props} />,
      'rewards': <LoadingSpinnerRewards {...this.props} />
    }

    return (
      <div className={classNames('LoadingSpinner', theme && `LoadingSpinner-${theme}`)}>
        {Component[theme]}
      </div>
    )
  }
}

LoadingSpinner.propTypes = {
  theme: PropTypes.string
}

export default withTheme(LoadingSpinner)
