import React from 'react'
import PropTypes from 'prop-types'
import Summary from '../Primitive/Summary'
import Content from '../Primitive/Content'
import FilterMenu from '../Primitive/FilterMenu'
import Title from '../Title'
import { Grid, GridItem } from '../Primitive/Grid'
import determineWidthStyle from '../../../../../components/site/widgets/offer-grid/lib/width-style-determiner'

const SortableOffersAus = (props) => {
  const { title, limit, width, menuIsOpen, selected, toggleMenu, selectItem, menu, regularFontWeight } = props
  const offers = limit ? props.offers.slice(0, limit) : props.offers
  const heroOffer = width === 'OneThree' ? offers.shift() : null
  return (
    <Content center gutter>
      <div className="SortableOffersHeader">
        <div className="SortableOffersFilter">
          <FilterMenu
            menu={menu}
            menuIsOpen={menuIsOpen}
            selected={selected}
            toggleMenu={toggleMenu}
            selectItem={selectItem}
          />
        </div>
        <div className="SortableOffersHeading">
          <Title
            title={title}
            size="ExtraLarge"
            regularFontWeight={regularFontWeight}
          />
        </div>
      </div>
      <Grid flex>
        { heroOffer &&
          <GridItem>
            <Summary
              image={{ url: heroOffer.imageUrl }}
              __fullUrlPath={heroOffer.url}
              category={heroOffer.category}
              shortHeadline={heroOffer.shortHeadline}
              displayShortHeadline={heroOffer.displayShortHeadline}
              status={heroOffer.status}
              width="One"
              FeaturedSummary
              split="five-ninths"
              openingDate={heroOffer.openingDate}
              showCountdownOverlay={heroOffer.showCountdownOverlay}
            />
          </GridItem>
        }
        {offers.map(({ _id, imageUrl, url, category, shortHeadline, displayShortHeadline, status, openingDate, showCountdownOverlay }) => (
          <GridItem key={_id} sizes={[ determineWidthStyle(width, offers.length) ]}>
            <Summary
              image={{ url: imageUrl }}
              __fullUrlPath={url}
              category={category}
              shortHeadline={shortHeadline}
              displayShortHeadline={displayShortHeadline}
              width={width}
              status={status}
              openingDate={openingDate}
              showCountdownOverlay={showCountdownOverlay}
            />
          </GridItem>
        ))}
      </Grid>
    </Content>
  )
}

SortableOffersAus.propTypes = {
  title: PropTypes.string,
  limit: PropTypes.number,
  offers: PropTypes.array,
  width: PropTypes.string,
  toggleMenu: PropTypes.func,
  selectItem: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  selected: PropTypes.string,
  menu: PropTypes.array,
  regularFontWeight: PropTypes.bool
}

SortableOffersAus.defaultProps = {
  offers: []
}

export default SortableOffersAus
