import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Button from '../Primitive/Button'
import { Grid, GridItem } from '../Primitive/Grid'
import moment from 'moment-timezone'
import withTheme from '../Theme/withTheme'
import OfferTerms from '../OfferTerms'

const UmbrellaChildOffer = (props) => {
  const { displayHeadline, sell, status, url, openingDate, theme, timezone, children, redeemClick, redeemClicked, state, termsAndConditions, redemptionData } = props
  const { formData } = redemptionData || {}
  const timeRedeemable = moment(openingDate).tz(timezone).fromNow()
  const ctaButtonText = (redemptionData && redemptionData.thankYouData && redemptionData.thankYouData.ctaText) ||
    (formData && formData.ctaText) ||
    'Redeem'

  return (
    <div className={classNames(
      'UmbrellaChildOffer',
      theme && `UmbrellaChildOffer-${theme}`
    )}>
      <div className="UmbrellaChildOfferTop">
        <Grid>
          <GridItem sizes={[ 'tablet-two-thirds' ]}>
            <h2 className="UmbrellaChildOfferTitle" dangerouslySetInnerHTML={{ __html: displayHeadline }} />
            <div className="UmbrellaChildOfferSell" dangerouslySetInnerHTML={{ __html: sell }} />
          </GridItem>
          {!redeemClicked &&
            <GridItem sizes={[ 'tablet-one-third' ]}>
              {status === 'Coming soon' && (
                <Fragment>
                  <Button className="UmbrellaChildOfferCTA" block disabled black href={url}>
                    {theme === 'aus' ? `Redeemable ${timeRedeemable}` : `Coming Soon`}
                  </Button>
                  {openingDate &&
                    <p className="UmbrellaChildOfferFeedback">
                      Available {moment(openingDate).tz(timezone).format('ll [at] LT z')}
                    </p>
                  }
                </Fragment>
              )}

              {[ 'Sold out', 'Closed' ].includes(status) && (
                <Button block disabled>{status}</Button>
              )}

              {![ 'Sold out', 'Closed', 'Coming soon' ].includes(status) && state !== 'limitReached' && (
                <Button block href={!redeemClick ? url : undefined} onClick={redeemClick} primary>
                  {ctaButtonText}
                </Button>
              )}

              {state === 'limitReached' &&
                <Button block className="RedemptionButtonCTA" uppercase disabled>Limit Reached</Button>
              }

            </GridItem>
          }
        </Grid>
      </div>
      {children &&
        children
      }
      {termsAndConditions && (
        <OfferTerms terms={termsAndConditions} gutterless />
      )}
    </div>
  )
}

UmbrellaChildOffer.propTypes = {
  displayHeadline: PropTypes.string,
  redeemClicked: PropTypes.bool,
  openingDate: PropTypes.object,
  sell: PropTypes.string,
  status: PropTypes.string,
  theme: PropTypes.string,
  url: PropTypes.string,
  timezone: PropTypes.string,
  redeemClick: PropTypes.func,
  children: PropTypes.node,
  state: PropTypes.string,
  termsAndConditions: PropTypes.string,
  redemptionData: PropTypes.object
}

export default withTheme(UmbrellaChildOffer)
