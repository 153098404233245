import React from 'react'
import PropTypes from 'prop-types'
import TextControl from '../TextControl'
import classNames from 'classnames'
import withTheme from '../../../Theme/withTheme'
import Button from '../../Button'
import Loading from '../../LoadingSpinner'
import ManualForm from './ManualForm'
import pick from 'lodash.pick'

const addressFields = [ 'addressLine1', 'addressLine2', 'locality', 'province', 'postalCode' ]

class AddressControl extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      results: [],
      search: '',
      loading: false,
      searchStatus: undefined,
      showManual: typeof props.value === 'object' && Object.entries(props.value).length > 0
    }

    if (props.config) {
      const { experianToken } = props.config
      this.fetchConfig = {
        headers: {
          'auth-token': experianToken
        }
      }

      this.requestAddresses = this.requestAddresses.bind(this)
      this.resetAddress = this.resetAddress.bind(this)
      this.addressChange = this.addressChange.bind(this)
    }

    if (props.value && typeof props.value === 'object') {
      Object.assign(this.state, { ...props.value })
    }
  }

  handleException (e) {
    window.Raven.context({
      extra: e.extra
    }, () => { throw e })
  }

  async requestAddresses (e) {
    this.setState({ loading: true, search: e.currentTarget.value })
    const search = encodeURIComponent(e.currentTarget.value)
    const reqUrl = `https://api.edq.com/capture/address/v2/search?query=${search}&country=AUS`

    try {
      const request = await fetch(reqUrl, this.fetchConfig)
      const result = await request.json()

      if (!request.ok) {
        const error = new Error(`${request.status}: ${request.statusText}`)
        error.extra = {
          result,
          headers: request.headers
        }
        throw error
      }

      this.setState({ results: result.results || [], loading: false, searchStatus: undefined })
    } catch (e) {
      this.setState({ results: [], loading: false })

      if (e.extra && e.extra.result && e.extra.result.Message === 'The Query field is required.') {
        return
      }

      this.handleException(e)
    }
  }

  addressChange (e) {
    const targetValue = e.currentTarget.value
    const state = Object.assign({}, this.state, {
      [ e.currentTarget.name ]: targetValue
    })

    this.setState(state)
    this.updateAddress(state)
  }

  async getAddress (url) {
    this.setState({ loading: true })
    const address = {
      addressLine1: '',
      addressLine2: '',
      addressLine3: '',
      locality: '',
      province: '',
      postalCode: '',
      country: ''
    }
    const state = { showManual: true, results: [], loading: false, search: '' }
    try {
      const request = await fetch(url, this.fetchConfig)
      const result = await request.json()

      if (!request.ok) {
        const error = new Error(`${request.status}: ${request.statusText}`)
        error.extra = {
          result,
          headers: request.headers
        }
        throw error
      }

      result.address.forEach(line => (
        Object.assign(address, line)
      ))

      if (!address.addressLine2) {
        address.addressLine2 = address.addressLine1
        address.addressLine1 = ''
      }
      Object.assign(state, address)
    } catch (e) {
      this.handleException(e)
    } finally {
      this.setState(state)
      this.updateAddress(state)
    }
  }

  updateAddress (address) {
    const { onChange, name } = this.props
    const filtered = pick(address, addressFields)

    onChange({
      target: {
        type: 'text'
      },
      currentTarget: {
        name,
        value: filtered
      }
    })
  }

  resetAddress () {
    const blank = addressFields.reduce((obj, item) => {
      obj[item] = ''
      return obj
    }, {})
    this.updateAddress(blank)
    this.setState({ showManual: false, ...blank })
  }

  render () {
    const { className, theme, value = {}, status } = this.props
    const { results, showManual, loading, search, searchStatus } = this.state
    const hasValue = typeof value === 'object' &&
      Object.entries(value).length > 0 &&
      Object.values(value).join('')
    return (
      <div className={classNames(
        className,
        'AddressControl',
        theme && `AddressControl-${theme}`
      )}>
        {!showManual &&
          <TextControl
            name="addressSearch"
            onChange={this.requestAddresses}
            status={status || searchStatus}
            element="input"
            value={search}
            placeholder="Search for an address..." />
        }

        {showManual && (
          <ManualForm
            {...this.state}
            status={status}
            onChange={this.addressChange} />
        )}

        {loading && <Loading />}

        {!loading && results.length > 0 && (
          <div className="AddressResults">
            {results.map((result, key) => (
              <Button className="AddressResult" block key={key} onClick={() => this.getAddress(result.format)} textButton>
                {result.suggestion}
              </Button>
            ))}
          </div>
        )}

        {!loading && results.length === 0 && !hasValue &&
          <Button textButton onClick={() => this.setState({ showManual: !showManual })}>
            {showManual ? 'Search for address' : 'Enter an address manually' }
          </Button>
        }

        {hasValue &&
          <Button textButton onClick={this.resetAddress}>
            Change Address
          </Button>
        }
      </div>
    )
  }
}

AddressControl.propTypes = {
  onChange: PropTypes.func,
  name: PropTypes.string.isRequired,
  status: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.object,
  className: PropTypes.string,
  theme: PropTypes.string,
  config: PropTypes.object
}

export default withTheme(AddressControl)
