import breakpoints from './breakpoints'
import createBreakpointManager from 'break'
import regg from 'regg'

import 'classlist-polyfill'
import 'element-closest'
import 'nodelist-foreach-polyfill'
import { EventEmitter } from 'events'
import config from '../whitelist-config.json'
import elementDataSet from 'element-dataset'

const env = process.env.NODE_ENV || 'development'
const serverEnv = process.env.SERVER_NODE_ENV || 'development'

const debug = require('debug')('base')
debug('Init')
elementDataSet()
debug('NODE_ENV', env)

const bm = createBreakpointManager()
const serviceLocator = regg()

window.Raven &&
  window.Raven.config(config.sentry.site.dsn, {
    environment: env,
    ignoreErrors: [],
    whitelistUrls: [
      /localhost\.clockhosting\.com/,
      /plusrewardssit\.com\.au/,
      /plusrewards\.com\.au/,
      /theaustralianplussit\.com\.au/,
      /theaustralianplus\.com\.au/,
    ],
  }).install()

// Look for an encoded article
const encodedEntityNode = document.querySelector('#encoded-entity')
if (encodedEntityNode) {
  const entityJson = encodedEntityNode.innerHTML
  try {
    window.__entity = JSON.parse(entityJson)
  } catch (e) {
    window.Raven.captureException(e)
    // eslint-disable-next-line no-console
    console.log(e)
  }
}

const components = [
  require('./smooth-scroll').default,
  require('./vertical-scroll-states').default,
  require('./mobile-navigation').default,
  require('./sticky/init').default,
  require('./toggler/init').default,
  require('./idaas-login-subscribe/init').default,
  require('./auth0-login-subscribe/init').default,
  require('./identity-login-redirect/init').default,
  require('./sortable-offers/init').default,
  require('./subscriber-benefits/init').default,
  require('./offer-carousel/init').default,
  require('./offer-images/init').default,
  require('./seasonal-hero/init').default,
  require('./offer-seasonal-hero/init').default,
  require('./redemption-area/init').default,
  require('./competition-winners/init').default,
  require('./filter-menu/init').default,
  require('./quotes/init').default,
  require('./gallery/init').default,
  require('./expander/init').default,
  require('./key-facts/init').default,
  require('./navigation-overlay/init').default,
  require('./hero-exists/init').default,
  require('./redemption-history-item/init').default,
  require('./cookie-policy/init').default,
  require('./countdown/init').default,
  require('./advent-calendar/init').default,
  require('./takeover/init').default
]

process.nextTick(() => {
  bm.add('mobile', breakpoints.mobileMq)
  bm.add('mobileNav', breakpoints.mobileNavMq)
})

serviceLocator.instancePath =
  document.querySelector('body').dataset.instancePath
serviceLocator.subscribeUrl =
  document.querySelector('body').dataset.subscribeUrl
serviceLocator.config = config
serviceLocator.register('breakpointManager', bm)
serviceLocator.env = env
serviceLocator.serverEnv = serverEnv

window.messageBus = new EventEmitter()

components.forEach((component) => component(serviceLocator, window))
