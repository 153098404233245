// Snow effect
// Stolen form https://codepen.io/wikyware-net/pen/rNmaqBz

let canvas, ctx
let points = []
let maxDist = 100

const initSnow = (canvasEl, wrapperClass) => {
  if (!canvasEl || !wrapperClass) return
  // Add on load scripts
  canvas = canvasEl
  ctx = canvas.getContext('2d')
  resizeCanvas(wrapperClass)
  pointFun()
  setInterval(pointFun, 30)
  window.addEventListener('resize', () => resizeCanvas(wrapperClass), false)
}
export default initSnow

// Particle constructor
const Point = () => {
  const point = {}
  point.x = Math.random() * (canvas.width + maxDist) - (maxDist / 2)
  point.y = Math.random() * (canvas.height + maxDist) - (maxDist / 2)
  point.z = (Math.random() * 0.5) + 0.5
  point.vx = ((Math.random() * 2) - 0.5) * point.z
  point.vy = ((Math.random() * 1.5) + 1.5) * point.z
  point.fill = 'rgba(255,255,255,' + ((0.6 * Math.random()) + 1) + ')'
  point.dia = ((Math.random() * 11) + 4) * point.z
  points.push(point)
}

// Point generator
const generatePoints = (amount) => {
  for (let i = 0; i < amount; i++) {
    /* eslint-disable no-new */
    new Point()
  }
}

// Point drawer
const draw = (obj) => {
  ctx.beginPath()
  ctx.strokeStyle = 'transparent'
  ctx.fillStyle = obj.fill
  ctx.arc(obj.x, obj.y, obj.dia, 0, 2 * Math.PI)
  ctx.closePath()
  ctx.stroke()
  ctx.fill()
}

// Updates point position values
const update = (obj) => {
  obj.x += obj.vx
  obj.y += obj.vy
  if (obj.x > canvas.width + (maxDist / 2)) {
    obj.x = -(maxDist / 2)
  } else if (obj.xpos < -(maxDist / 2)) {
    obj.x = canvas.width + (maxDist / 2)
  }
  if (obj.y > canvas.height + (maxDist / 2)) {
    obj.y = -(maxDist / 2)
  } else if (obj.y < -(maxDist / 2)) {
    obj.y = canvas.height + (maxDist / 2)
  }
}

const pointFun = () => {
  ctx.clearRect(0, 0, canvas.width, canvas.height)
  for (let i = 0; i < points.length; i++) {
    draw(points[i])
    update(points[i])
  }
}

const resizeCanvas = (wrapperClass) => {
  canvas.width = document.querySelector(wrapperClass).offsetWidth
  canvas.height = document.querySelector(wrapperClass).offsetHeight
  points = []
  generatePoints(window.innerWidth / 30)
  pointFun()
}
