import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'

class Figure extends PureComponent {
  render () {
    const { imgUrl, alt, caption, credits, className, theme, modifiers, children, ...other } = this.props
    return (
      <figure {...other} className={classNames(
        'Figure',
        theme && `Figure-${theme}`,
        modifiers && modifiers.map(modifier => modifier),
        className
      )}>
        <div className="FigureImage">
          <img src={imgUrl} alt={alt || 'Figure'} />
          {caption && <figcaption className="FigureCaption">{caption}</figcaption>}
          {children && (
            <div className="FigureOverlay">
              {children}
            </div>
          )}
        </div>
        {credits && <figcaption className="FigureCaption">{credits}</figcaption>}
      </figure>
    )
  }
}

Figure.propTypes = {
  imgUrl: PropTypes.string.isRequired,
  alt: PropTypes.string,
  className: PropTypes.string,
  caption: PropTypes.string,
  credits: PropTypes.string,
  theme: PropTypes.string,
  modifiers: PropTypes.array,
  children: PropTypes.node
}

export default withTheme(Figure)
