import React from 'react'
import PropTypes from 'prop-types'
import FieldTemplate from '../Primitive/FieldTemplate'
import Control from '../Primitive/Control'

const disallowedPlaceholderTypes = [ 'select', 'radio', 'checkbox' ]
const optionMap = item => ({ text: item.response, value: item.response })
const defaultValue = type => {
  if (type === 'address') {
    return {}
  }

  return ''
}
const FormBuilderQuestion = ({ question, onChange, data = {}, error = {}, id, config = {} }) => {
  const hasResponses = question.responses && question.responses.length > 0
  const options = hasResponses ? question.responses.map(response => optionMap(response)) : null

  const showPlaceholderInsteadOfLabel = question.placeholderAsQuestion && !disallowedPlaceholderTypes.includes(question.inputType)
  return (
    <FieldTemplate
      controlName={`question-${id}`}
      value={data[`question-${id}`] || ''}
      feedback={error[`question-${id}`]}
      type={question.inputType}
      wordLimit={question.wordLimit}
      template={question.inputType}
      hideLabel={showPlaceholderInsteadOfLabel}
      label={!showPlaceholderInsteadOfLabel && question.question}
      required={!question.placeholderAsQuestion && question.required}
      assistance={question.description}
      status={error[`question-${id}`] && 'error'}
    >
      <Control
        name={`question-${id}`}
        onChange={onChange}
        type={question.inputType}
        value={data[`question-${id}`] || defaultValue(question.inputType)}
        status={error[`question-${id}`] && 'error'}
        config={config}
        selected={data[`question-${id}`] || ''}
        label={!showPlaceholderInsteadOfLabel && question.question}
        placeholder={showPlaceholderInsteadOfLabel ? question.question : ''}
        options={question.inputType === 'select'
          ? [ { text: '--- Please Select ---', value: '' }, ...options ]
          : options} />
    </FieldTemplate>
  )
}

FormBuilderQuestion.propTypes = {
  question: PropTypes.object,
  onChange: PropTypes.func,
  data: PropTypes.object,
  error: PropTypes.object,
  id: PropTypes.number,
  config: PropTypes.object
}

export default FormBuilderQuestion
