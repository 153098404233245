import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '../Primitive/Button'
import Prose from '../Primitive/Prose'

class HeroCta extends PureComponent {
  render() {
    const {
      loginOnClick,
      subscribeOnClick,
      isLoggedIn,
      offerUrl,
      homepageHeroCtaLabel
    } = this.props
    const buttonText = homepageHeroCtaLabel || 'Find out more'
    if (isLoggedIn) {
      return (
        <Fragment>
          <Button className="HeroCTA" primary href={offerUrl}>
            {buttonText}
          </Button>
        </Fragment>
      )
    }
    return (
      <Fragment>
        <Button className="HeroCTA" login onClick={loginOnClick}>
          Log in
        </Button>
        <Prose className="HeroSmall">
          <span>
            Not a Member?{' '}
            <a href="#" onClick={subscribeOnClick}>
              Subscribe now
            </a>
          </span>
        </Prose>
      </Fragment>
    )
  }
}

HeroCta.propTypes = {
  loginOnClick: PropTypes.func,
  subscribeOnClick: PropTypes.func,
  isLoggedIn: PropTypes.bool,
  offerUrl: PropTypes.string,
  redemptionType: PropTypes.string,
  homepageHeroCtaLabel: PropTypes.string
}

export default HeroCta
