
import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import TextControl from '../TextControl'
import FieldTemplate from '../../FieldTemplate'
import withTheme from '../../../Theme/withTheme'

const ManualForm = (props) => {
  const {
    onChange,
    addressLine1,
    addressLine2,
    locality,
    province,
    postalCode,
    status
  } = props
  return (
    <Fragment>
      <FieldTemplate
        controlName="addressLine1"
        label=""
        hideLabel
        type="text">
        <TextControl
          id="addressLine1"
          placeholder="Business Name (optional)"
          value={addressLine1}
          name="addressLine1"
          onChange={onChange}
          element="input"
        />
      </FieldTemplate>

      <FieldTemplate
        controlName="addressLine2"
        label=""
        hideLabel
        type="text">
        <TextControl
          id="addressLine2"
          placeholder="Street Address"
          value={addressLine2}
          name="addressLine2"
          onChange={onChange}
          element="input"
          status={!addressLine2 ? status : undefined}
        />
      </FieldTemplate>

      <FieldTemplate
        controlName="locality"
        label=""
        hideLabel
        type="text">
        <TextControl
          id="locality"
          value={locality}
          placeholder="Suburb"
          name="locality"
          onChange={onChange}
          element="input"
          status={!locality ? status : undefined}
        />
      </FieldTemplate>

      <FieldTemplate
        controlName="province"
        label=""
        hideLabel
        type="text">
        <TextControl
          id="province"
          value={province}
          name="province"
          placeholder="State"
          onChange={onChange}
          element="input"
          status={!province ? status : undefined}
        />
      </FieldTemplate>

      <FieldTemplate
        controlName="postalCode"
        label=""
        hideLabel
        type="text">
        <TextControl
          id="postalCode"
          value={postalCode}
          name="postalCode"
          placeholder="Post Code"
          onChange={onChange}
          element="input"
          status={!postalCode ? status : undefined}
        />
      </FieldTemplate>
    </Fragment>
  )
}

ManualForm.propTypes = {
  onChange: PropTypes.func,
  addressLine1: PropTypes.string,
  addressLine2: PropTypes.string,
  locality: PropTypes.string,
  province: PropTypes.string,
  postalCode: PropTypes.string,
  status: PropTypes.string
}

export default withTheme(ManualForm)
