import { booleanToCallback, createValidatorAllowingFailureMessageOverride } from 'validity'

export default createValidatorAllowingFailureMessageOverride(booleanToCallback(val => {
  const fields = [ 'addressLine2', 'locality', 'province', 'postalCode' ]

  for (const field of fields) {
    if (!val[field]) {
      return false
    }
  }

  return true
}), `You must fill in the street name, suburb, state and post code`)
