import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'
import ClipDecoration from './ClipPathDecoration'

class AdventCalendarItem extends PureComponent {
  getItemLabel (state) {
    const labels = {
      Available: 'Smash this now',
      Redeemed: 'Redeemed',
      'Sold out': 'You missed out this time',
      'Coming soon': this.props.comingSoonMessage
    }

    return labels[state]
  }

  render () {
    const { theme, image, title, color, state, offerUrl, onClick } = this.props
    const isAvailable = state === 'Available'
    const isSoldOut = state === 'Sold out'
    const showTitle = title && (state === 'Redeemed' || state === 'Sold out')
    const WrapperEl = isAvailable ? 'button' : 'a'
    const label = this.getItemLabel(state)
    const stateMap = {
      'Sold out': 'soldOut',
      'Redeemed': 'redeemed',
      'Coming soon': 'comingSoon'
    }

    return (
      <WrapperEl
        className={classNames(
          'AdventCalendarItem',
          theme && `AdventCalendarItem-${theme}`,
          color && `AdventCalendarItem--${color}`,
          state && `AdventCalendarItem--${stateMap[state]}`
        )}
        onClick={isAvailable && onClick}
        href={isSoldOut && offerUrl}
      >
        <div className="AdventCalendarItem__Outer">
          <div className="AdventCalendarItem__Inner">
            <ClipDecoration />
            <div className="AdventCalendarItem__SoldOut">
              <span>Sold Out</span>
            </div>
            <div className="AdventCalendarItem__ImageWrapper">
              <img
                src={image}
                alt={label}
                className="AdventCalendarItem__Image"
              />
            </div>
            <div className="AdventCalendarItem__Overlay" />
          </div>
          {showTitle && (
            <div
              className="AdventCalendarItem__Title"
              dangerouslySetInnerHTML={{ __html: title }}
            />
          )}
          {label && <h3 className="AdventCalendarItem__Label">{label}</h3>}
        </div>
      </WrapperEl>
    )
  }
}

AdventCalendarItem.propTypes = {
  theme: PropTypes.string,
  image: PropTypes.string,
  color: PropTypes.oneOfType([ 'purple', 'green', 'blue', 'orange', 'pink' ])
    .isRequired,
  title: PropTypes.string,
  state: PropTypes.oneOf([ 'available', 'redeemed', 'soldOut', 'comingSoon' ])
    .isRequired,
  offerUrl: PropTypes.string,
  comingSoonMessage: PropTypes.string,
  onClick: PropTypes.func
}

export default withTheme(AdventCalendarItem)
