import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import withTheme from '../../component/Theme/withTheme'
import SortableOffersAus from './SortableOffersAus'
import SortableOffersRewards from './SortableOffersRewards'

class SortableOffers extends PureComponent {
  constructor (props) {
    super(props)

    this.menu = [
      { value: 'latest', text: 'Latest' },
      { value: 'closingSoon', text: 'Closing Soon' }
    ]

    this.state = {
      menuIsOpen: false,
      selected: props.theme === 'rewards' ? this.menu.find(item => item.value === props.sort) && this.menu.find(item => item.value === props.sort).text : 'Sort by'
    }

    this.toggleMenu = this.toggleMenu.bind(this)
    this.selectItem = this.selectItem.bind(this)
  }

  toggleMenu () {
    this.setState({ menuIsOpen: !this.state.menuIsOpen })
  }

  selectItem (item) {
    this.setState({
      menuIsOpen: !this.state.menuIsOpen,
      selected: item.text
    })
    this.props.sortChanged(item.value)
  }

  render () {
    const { theme } = this.props
    const offers = this.props.offers ? [ ...this.props.offers ] : []
    const Component = {
      'aus': <SortableOffersAus menu={this.menu} toggleMenu={this.toggleMenu} selectItem={this.selectItem} {...this.state} {...this.props} offers={offers} />,
      'rewards': <SortableOffersRewards menu={this.menu} toggleMenu={this.toggleMenu} selectItem={this.selectItem} {...this.state} {...this.props} offers={offers} />
    }

    return (
      <div className={classNames('SortableOffers', theme && `SortableOffers-${theme}`)}>
        {Component[theme]}
      </div>
    )
  }
}

SortableOffers.propTypes = {
  theme: PropTypes.string,
  sort: PropTypes.string,
  offers: PropTypes.array,
  sortChanged: PropTypes.func
}

export default withTheme(SortableOffers)
