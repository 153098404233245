const breakpoints = {}

breakpoints.mobile = { start: 0, finish: 549 }
breakpoints.mobileMq = '(min-width: ' + breakpoints.mobile.start +
  'px) and (max-width: ' + breakpoints.mobile.finish + 'px)'
breakpoints.mobileNav = { start: 0, finish: 799 }
breakpoints.mobileNavMq = '(min-width: ' + breakpoints.mobileNav.start +
  'px) and (max-width: ' + breakpoints.mobileNav.finish + 'px)'

export default breakpoints
