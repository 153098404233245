import React from 'react'
import PropTypes from 'prop-types'

const ReactMounter = (props) => {
  const { className, children, clientSideProps } = props
  return (
    <div
      className={className}
      data-props={JSON.stringify({ ...clientSideProps })}
    >
      {children}
    </div>
  )
}

ReactMounter.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node,
  clientSideProps: PropTypes.object
}

export default ReactMounter
