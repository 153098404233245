import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../../component/Theme/withTheme'
import Button from '../Button'
import Icon from '../Icon'

class FilterMenuAus extends PureComponent {
  render () {
    const { theme, toggleMenu, selectItem, menuIsOpen, selected, menu } = this.props
    return (
      <div className={classNames(
        'FilterMenu',
        theme && `FilterMenu-${theme}`,
        menuIsOpen && `open`
      )}>
        <Button
          onClick={toggleMenu}
          className="FilterMenuButton"
          ref={toggle => { this.toggle = toggle }}
        >
          {selected || 'Sort by'}
          <Icon
            type="ChevronDown"
            className="FilterMenuButtonIcon"
            width={12}
          />
        </Button>
        <div className="FilterMenuChildren">
          {menu.map((item, i) => (
            <span
              className="FilterMenuItem"
              onClick={() => selectItem(item)}
              key={`FilterMenuItem${i}`}
            >
              {item.text}
            </span>
          ))}
        </div>
      </div>
    )
  }
}

FilterMenuAus.propTypes = {
  theme: PropTypes.string,
  toggleMenu: PropTypes.func,
  selectItem: PropTypes.func,
  menuIsOpen: PropTypes.bool,
  menu: PropTypes.array,
  selected: PropTypes.string
}

export default withTheme(FilterMenuAus)
