const determineEmphasisedText = (text = '', emphasisedWordLocation) => {
  const texts = {
    emphasisedText: '',
    unemphasisedText: text
  }

  if (emphasisedWordLocation === 'First') {
    texts.emphasisedText = text.substring(0, text.indexOf(' '))
    texts.unemphasisedText = text.substring(text.indexOf(' ') + 1)
  }

  if (emphasisedWordLocation === 'Last') {
    texts.emphasisedText = text.substring(text.lastIndexOf(' ') + 1)
    texts.unemphasisedText = text.substring(0, text.lastIndexOf(' '))
  }

  if (emphasisedWordLocation === 'All') {
    texts.emphasisedText = text
    texts.unemphasisedText = ''
  }

  return texts
}

export default determineEmphasisedText
