import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import withTheme from '../Theme/withTheme'

import Icon from '../Primitive/Icon'
import Button from '../Primitive/Button'
import Menu from '../Primitive/Menu'

class UserActions extends PureComponent {
  render () {
    const {
      firstName,
      handleSubscribe,
      handleLogin,
      handleMyAccount,
      handleLogout,
      paperName,
      paperUrl,
      instances,
      instancePath
    } = this.props

    return (
      <Fragment>
        <Button className="UserActionsButton is-hidden-mobile is-hidden-tablet-portrait" target="_blank" href={paperUrl}>
          <Icon type="ChevronLeft" />
          <span>Visit {paperName}</span>
        </Button>
        <div className="UserActionsWrapper">
          <Menu className="UserActionsNewspaperSelector" label="Newspaper">
            {instances.map((instance, i) =>
              <div className="MenuItem" key={i}><a href={instance.subdomain}>{instance.name}</a></div>
            )}
          </Menu>
          {firstName ? (
            <Menu className="UserActionsUserMenu" icon="Avatar" label={`Hi ${firstName}`}>
              {handleMyAccount && <div className="MenuItem" onClick={handleMyAccount}>My Account</div>}
              <div className="MenuItem"><a href={`${instancePath}my-rewards`}>My +Rewards</a></div>
              <div className="MenuItem" onClick={handleLogout}>Logout</div>
            </Menu>
          ) : (
            <div>
              <Button href="#" onClick={handleSubscribe} className="UserActionsButton UserActionsButton-subscribe">
                <Icon type="Plus" />
                <span>Subscribe</span>
              </Button>
              <Button href="#" onClick={handleLogin} primary className="UserActionsButton UserActionsButton-login">
                Log in
              </Button>
            </div>
          )}
        </div>
      </Fragment>
    )
  }
}

UserActions.propTypes = {
  firstName: PropTypes.string,
  handleLogin: PropTypes.func,
  handleSubscribe: PropTypes.func,
  handleMyAccount: PropTypes.func,
  handleLogout: PropTypes.func,
  paperName: PropTypes.string,
  paperUrl: PropTypes.string,
  instances: PropTypes.array,
  instancePath: PropTypes.string
}

export default withTheme(UserActions)
