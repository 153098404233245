import React, { PureComponent } from 'react'
import { node } from 'prop-types'

class VisuallyHidden extends PureComponent {
  render () {
    const { children } = this.props
    return <span className="VisuallyHidden">{children}</span>
  }
}

VisuallyHidden.displayName = 'VisuallyHidden'

VisuallyHidden.propTypes = {
  children: node.isRequired
}

export default VisuallyHidden
