
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import withTheme from '../Theme/withTheme'

import Form from '../Primitive/Form'
import Icon from '../Primitive/Icon'
import Button from '../Primitive/Button'
import TextControl from '../Primitive/Control/TextControl'
import IconButton from '../Primitive/IconButton'
import { widthMax, tablet } from '../Primitive/Media'

class Search extends PureComponent {
  constructor () {
    super()
    this.state = {
      searchOpen: false
    }
    this.toggle = this.toggle.bind(this)
  }

  toggle () {
    this.search.classList.toggle('is-open')
    this.setState({ menuOpen: !this.state.menuOpen })
  }

  componentDidMount () {
    const breakpoint = window.matchMedia(widthMax(tablet))
    const query = e => handleSearch(e)
    breakpoint.addListener(query)

    const handleSearch = breakpoint => {
      if (breakpoint.matches) {
        this.search.classList.add('is-open')
        this.setState({ searchOpen: true })
      } else {
        this.search.classList.remove('is-open')
        this.setState({ searchOpen: false })
      }
    }

    handleSearch(breakpoint)
  }

  render () {
    const { theme } = this.props

    return (
      <div
        className={classNames(
          'Search',
          theme && `Search-${theme}`
        )}
        ref={(search) => { this.search = search }}
      >
        <Icon width={12} height={12} type="Search" />
        <Button className="SearchOpen" onClick={this.toggle}>Search</Button>
        <Form method="get" action="/search" className="SearchForm" {...this.props}>
          <TextControl
            name="q"
            type="text"
            placeholder="Search"
            className="SearchInput"
          />
        </Form>
        <IconButton small icon="Close" className="SearchClose" onClick={this.toggle} />
      </div>
    )
  }
}

Search.propTypes = {
  theme: PropTypes.string
}

export default withTheme(Search)
