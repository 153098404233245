export default async (data, instancePath, offerId, apiRoute, errorMessage, formData) => {
  if (window.__entity) {
    data.parent = window.__entity._id
  }
  const body = JSON.stringify(data)
  let res = null
  let responseText = null
  try {
    res = await fetch(`${instancePath}api/${apiRoute}/${offerId}${window.location.search}`, {
      headers: { 'content-type': 'application/json' },
      credentials: 'include',
      method: 'POST',
      body
    })
    responseText = await res.text()
    let data = { title: 'Sorry', html: '<p>Sorry, something went wrong. Please try again</p>' }
    // indicates sold out
    if (res.status === 404) {
      data = { title: 'Sold Out', html: '<p>Sorry, this offer is now sold out</p>' }
    } else if (res.status === 503) {
      const message = 'This offer is currently very popular and we have been unable to process your request. Please try again shortly.'
      if (formData) {
        return { waiting: false, errorMessage: message, error: { error: message } }
      }
      data = { title: 'Sorry', html: `<p>${message}</p>` }
    } else if (res.status === 200) {
      data = JSON.parse(responseText)
      window.messageBus.emit('redemptionRedeemed')
      if (data.limitReached) {
        window.messageBus.emit('disableOthers')
      }
    } else if (window.Raven) {
      window.Raven.captureException(new Error(`Error when ${errorMessage}: ${res.status}`))
    }
    return { redemptionInfoLoaded: true, waiting: false, thankYouData: data }
  } catch (e) {
    if (window.Raven) {
      window.Raven.setExtraContext({ extra: { responseText } })
      window.Raven.captureException(new Error(`Error when ${errorMessage}: ${res.status} - ${e.message}`))
      window.Raven.setExtraContext()
    }
    const message = 'We were not able to process your request at this time. Please try again shortly.'
    if (formData) {
      return { waiting: false, errorMessage: message, error: { error: message } }
    }
    const data = { title: 'Sorry', html: `<p>${message}</p>` }
    return { redemptionInfoLoaded: true, waiting: false, thankYouData: data }
  }
}
