import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'
import ModalPortal from '../Modal/ModalPortal'
import Icon from '../Icon'

class Overlay extends PureComponent {
  constructor (props) {
    super(props)
    this.handleEscape = this.handleEscape.bind(this)
  }

  componentDidMount () {
    if (this.props.onOpen) {
      this.props.onOpen()
    }
    document.addEventListener('keydown', this.handleEscape, false)
  }

  componentWillUnmount () {
    document.removeEventListener('keydown', this.handleEscape, false)
  }

  handleEscape (e) {
    if (e.keyCode === 27) {
      this.props.handleClose()
    }
  }

  render () {
    const { children, theme, handleClose, className } = this.props
    const getIconFromTheme = {
      'aus': 'Close',
      'rewards': 'Cross'
    }

    return (
      <ModalPortal>
        <div className={classNames(
          'Overlay',
          `Overlay-${theme}`,
          className
        )}>
          <div className="OverlayInner" onClick={handleClose} tabIndex={-1} />
          <div role="document">
            <div className={classNames(
              'OverlayModal'
            )}>
              <div className="ModalInner">
                <Icon
                  type={getIconFromTheme[theme]}
                  width={20}
                  height={20}
                  className="ModalClose"
                  onClick={handleClose}
                />
                {children}
              </div>
            </div>
          </div>
        </div>
      </ModalPortal>
    )
  }
}

Overlay.propTypes = {
  children: PropTypes.node.isRequired,
  handleClose: PropTypes.func.isRequired,
  theme: PropTypes.string,
  onOpen: PropTypes.func,
  className: PropTypes.string
}

export default withTheme(Overlay)
