import React, { Fragment } from 'react'
import Prose from '../../../../layout/component/Primitive/Prose'
import PropTypes from 'prop-types'
import Button from '../../../../layout/component/Primitive/Button'
import LoadingSpinner from '../../../../layout/component/Primitive/LoadingSpinner'

const ResendEmail = props => {
  const {
    resendEmailOnClick,
    emailRequest,
    displayText,
    theme
  } = props
  return (
    <Fragment>
      {!emailRequest && (
        <Fragment>
          {!displayText &&
            <Fragment>
              {theme === 'aus' ? (
                <Button
                  primaryInverse
                  onClick={resendEmailOnClick}>Resend Email
                </Button>
              ) : (
                <Button
                  primary
                  onClick={resendEmailOnClick}>Resend Email
                </Button>
              )}
            </Fragment>
          }
          {displayText &&
            <Prose>
              As you've purchased before, <a onClick={resendEmailOnClick}>click here</a> to resend the email.
            </Prose>
          }
        </Fragment>
      )}
      {emailRequest === 'loading' && (
        <LoadingSpinner />
      )}
      {emailRequest === 'success' && (
        <p>The email has been successfully sent</p>
      )}
      {emailRequest === 'failed' && (
        <p>There was an error whilst sending the email</p>
      )}
    </Fragment>
  )
}

ResendEmail.propTypes = {
  resendEmailOnClick: PropTypes.func,
  emailRequest: PropTypes.string,
  displayText: PropTypes.bool,
  theme: PropTypes.string
}

export default ResendEmail
