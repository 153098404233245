import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import Carousel from '../Primitive/Carousel'
import Icon from '../Primitive/Icon'
import HeroCta from '../HeroCta'

class Hero extends PureComponent {
  render() {
    const {
      offers,
      logoImageUrl,
      logoLink,
      theme,
      isLoggedIn,
      loginOnClick,
      subscribeOnClick,
      isRehydrated
    } = this.props
    const HeroItem = (offer) => {
      const {
        category,
        sell,
        displayShortHeadline,
        image,
        __fullUrlPath,
        status,
        startHidden,
        index,
        redemptionExperiences,
        homepageHeroCtaLabel
      } = offer
      let className = 'HeroItem'
      if (startHidden) {
        className += ' is-hidden'
      }

      const redemptionType =
        redemptionExperiences &&
        redemptionExperiences[0] &&
        redemptionExperiences[0].redemptionType
      const heroCtaProps = {
        offerUrl: __fullUrlPath,
        homepageHeroCtaLabel,
        isLoggedIn,
        loginOnClick,
        subscribeOnClick,
        redemptionType
      }
      const Wrapper = ({ children }) =>
        theme === 'rewards' ? (
          <div className={className}>{children}</div>
        ) : (
          <a className={className} href={__fullUrlPath}>
            {children}
          </a>
        )
      return (
        <Wrapper>
          {image && (
            <Fragment>
              <style
                dangerouslySetInnerHTML={{
                  __html: `
                .HeroImage${index} {
                  background-image: url(${image.mobileUrl});
                }
                @media screen and (min-width: 550px) {
                  .HeroImage${index} {
                    background-image: url(${image.url});
                  }
                }`
                }}
              />
              <div className={`HeroImage HeroImage${index}`} />
            </Fragment>
          )}
          <div className="HeroInner">
            {status && status !== 'Available' && theme === 'aus' && (
              <span className="HeroRoundel">{status}</span>
            )}
            {category && theme !== 'rewards' && (
              <h2 className="HeroCategory">{category}</h2>
            )}
            <h2
              className="HeroHeadline"
              dangerouslySetInnerHTML={{ __html: displayShortHeadline }}
            />
            {sell && (
              <h3
                className="HeroSell"
                dangerouslySetInnerHTML={{ __html: sell }}
              />
            )}
            {theme === 'rewards' && <HeroCta {...heroCtaProps} />}
          </div>
        </Wrapper>
      )
    }

    return (
      <div className={classNames('Hero', theme && `Hero-${theme}`)}>
        {theme === 'rewards' && (
          <a href={logoLink} className="HeroLogo">
            <img src={logoImageUrl} />
            <Icon width={323} type="PlusRewardsForYou" />
          </a>
        )}
        {offers.length > 1 ? (
          <Carousel autoplay>
            {offers.map((offer, i) => (
              <HeroItem
                index={i}
                key={`heroitem${i}`}
                {...offer}
                startHidden={i > 0 && !isRehydrated}
              />
            ))}
          </Carousel>
        ) : (
          <HeroItem index={0} {...offers[0]} />
        )}
      </div>
    )
  }
}

Hero.propTypes = {
  offers: PropTypes.array,
  theme: PropTypes.string,
  logoImageUrl: PropTypes.string,
  logoLink: PropTypes.string,
  isLoggedIn: PropTypes.bool,
  isRehydrated: PropTypes.bool,
  loginOnClick: PropTypes.func,
  subscribeOnClick: PropTypes.func
}

export default withTheme(Hero)
