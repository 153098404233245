import React from 'react'
import ReactDOM from 'react-dom'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'
import RedemptionHistoryItem from './RedemptionHistoryItem'

export default (serviceLocator) => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-redemption-history-item').forEach(appNode => {
      const theme = document.querySelector('body').dataset.theme
      const props = JSON.parse(appNode.dataset.props) || {}
      ReactDOM.render(
        <ThemeContext.Provider value={theme}>
          <RedemptionHistoryItem
            {...props}
            instancePath={serviceLocator.instancePath}
          />
        </ThemeContext.Provider>,
        appNode
      )
    })
  })
}
