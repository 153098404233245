import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'
import Countdown from '../Countdown'

class SummaryRewards extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      countdownEnded: false
    }
  }

  render () {
    const {
      displayShortHeadline,
      shortHeadline,
      image,
      keyFactsTitle,
      extraInfo,
      __fullUrlPath,
      status,
      additionalHeadlineClass,
      additionalOverlayClass,
      openingDate,
      showCountdownOverlay
    } = this.props

    const endCountdown = () => this.setState({ countdownEnded: true })
    const shouldShowCountdown = () => {
      return status === 'Coming soon' &&
        !this.state.countdownEnded &&
        showCountdownOverlay
    }

    return (
      <a href={__fullUrlPath} className="SummaryRewardsWrapper">
        <div className="Summary-rewards">
          <div className="SummaryImage">
            <img src={image.url} alt={shortHeadline} />
            { ![ 'Available', 'Sold out', 'Closed' ].includes(status) &&
                !this.state.countdownEnded &&
                  <div className="SummaryRibbon">
                    <span>{status}</span>
                  </div>
            }
            { [ 'Sold out', 'Closed' ].includes(status) &&
              <div className={classNames(
                'SummaryOverlay',
                additionalOverlayClass
              )}>
                <span>{status}</span>
              </div>
            }
            { shouldShowCountdown()
              ? <div className={classNames(
                'SummaryOverlay',
                additionalOverlayClass
              )}>
                <Countdown openingDate={openingDate} onCountdownEnd={endCountdown} />
              </div> : null
            }
          </div>
          <div className="SummaryInner">
            <div className="SummaryTag">{keyFactsTitle}{ extraInfo && ` | ${extraInfo}` }</div>
            <h2
              className={classNames(
                'SummaryHeadline',
                additionalHeadlineClass
              )} dangerouslySetInnerHTML={{ __html: displayShortHeadline }} />
          </div>
        </div>
      </a>
    )
  }
}

SummaryRewards.propTypes = {
  displayShortHeadline: PropTypes.string,
  shortHeadline: PropTypes.string,
  image: PropTypes.object,
  keyFactsTitle: PropTypes.string,
  extraInfo: PropTypes.string,
  __fullUrlPath: PropTypes.string,
  status: PropTypes.string,
  openingDate: PropTypes.string,
  additionalHeadlineClass: PropTypes.string,
  additionalOverlayClass: PropTypes.string,
  showCountdownOverlay: PropTypes.bool
}

export default withTheme(SummaryRewards)
