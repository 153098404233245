import React from 'react'
import PropTypes from 'prop-types'
import BaseFormBuilder from '../form-builder/BaseFormBuilder'
import PurchaseRedemptionArea from '../../../site/widgets/offer-layout/redemption-area/component/PurchaseRedemptionArea'
import withTheme from '../../../site/layout/component/Theme/withTheme'

class RedemptionAreaContainer extends BaseFormBuilder {
  constructor (props) {
    super(props)
    this.submitForm = this.submitForm.bind(this)
    this.makePreparationRequest = this.makePreparationRequest.bind(this)
    this.handleChangeDetails = this.handleChangeDetails.bind(this)
    this.showError = this.showError.bind(this)
    this.handleSendEmail = this.handleSendEmail.bind(this)
    this.state = {
      ...this.state
    }
  }

  async makePreparationRequest () {
    const data = {
      formId: this.props.formData ? this.props.formData._id : null,
      formData: this.state.data,
      parent: window.__entity && window.__entity._id
    }
    const body = JSON.stringify(data)
    try {
      const res = await fetch(`${this.props.instancePath}api/payment/preparation/${this.props.offerId}`, {
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        method: 'POST',
        body
      })
      if (res.status !== 200) return this.showError(res)
      const data = await res.json()
      if (data.limitReached) {
        window.messageBus.emit('disableOthers')
      }
      const state = {
        authKey: data.authKey,
        pendingPaymentId: data.pendingPaymentId,
        waiting: false,
        submissionSuccess: true
      }
      this.setState(state)
      window.addEventListener('message', message => {
        if (typeof message.data === 'string') {
          const response = JSON.parse(message.data)
          if (response.success) return this.setState({ paymentSuccess: true, paymentFailed: false })
          // serious error occured
          if (!response.authKey) return this.showError(500)
          this.setState({ paymentFailed: true, authKey: response.authKey })
        }
      }, false)
    } catch (e) {
      this.showError(500)
    }
  }

  submitForm (e) {
    e.preventDefault()
    this.props.schema.validate(this.state.data, (err, errors) => {
      if (err) return alert(err)
      if (Object.keys(errors).length > 0) return this.setState({ error: errors })
      this.setState({ waiting: true, error: {} })
      this.makePreparationRequest()
    })
  }

  async handleChangeDetails (e) {
    e.preventDefault()
    this.setState({ waiting: true })
    try {
      const res = await fetch(`${this.props.instancePath}api/payment/clear`, {
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        method: 'POST',
        body: JSON.stringify({ pendingPaymentId: this.state.pendingPaymentId })
      })
      if (res.status !== 200) {
        window.Raven && window.Raven.captureException(new Error('Error when redeeming purchase: ' + res.status))
        return this.showError(res.status)
      }
      window.messageBus.emit('enableOthers')
      this.setState({ waiting: false, submissionSuccess: false })
    } catch (e) {
      window.Raven && window.Raven.captureException('Error when redeeming purchase')
      return this.showError(500)
    }
  }

  async showError (res) {
    let data = { title: 'Sorry', html: '<p>Sorry, something went wrong. Please try again</p>' }
    // indicates sold out
    if (res.status === 404) {
      const { title, message } = await res.json()
      if (title && message) {
        data = { title, html: `<p>${message}</p>` }
      } else {
        data = { title: 'Sold Out', html: '<p>Sorry, this offer is now sold out</p>' }
      }
    } else if (res.status === 409) {
      data = { title: 'Sorry', html: '<p>We were unable to process your request. Please try again in 10 minutes.</p>' }
    }
    const state = { waiting: false, submissionSuccess: true, paymentSuccess: true, thankYouData: data }
    return this.setState(state)
  }

  async handleSendEmail () {
    this.setState({ emailRequest: 'loading' })
    try {
      const res = await fetch(`${this.props.instancePath}api/payment/resend/${this.props.offerId}`, {
        headers: { 'content-type': 'application/json' },
        credentials: 'include',
        method: 'POST'
      })
      if (res.status !== 200) return this.handleSendEmailError()
      this.setState({ emailRequest: 'success' })
    } catch (e) {
      this.handleSendEmailError()
    }
  }

  handleSendEmailError () {
    this.setState({ emailRequest: 'failed' })
  }

  render () {
    return <PurchaseRedemptionArea
      submitForm={this.submitForm}
      onChange={this.onChange}
      changeDetailsOnClick={this.handleChangeDetails}
      resendEmailOnClick={this.handleSendEmail}
      {...this.props}
      {...this.state} />
  }
}

RedemptionAreaContainer.propTypes = {
  instancePath: PropTypes.string,
  offerId: PropTypes.string
}

export default withTheme(RedemptionAreaContainer)
