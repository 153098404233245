import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import withTheme from '../Theme/withTheme'
import UserActionsAus from './UserActionsAus'
import UserActionsRewards from './UserActionsRewards'

class UserActions extends PureComponent {
  render () {
    const { theme } = this.props

    const Component = {
      'aus': <UserActionsAus {...this.props} />,
      'rewards': <UserActionsRewards {...this.props} />
    }

    return (
      <div className={classNames(
        'UserActions',
        theme && `UserActions-${theme}`
      )}>
        {Component[theme]}
      </div>
    )
  }
}

UserActions.propTypes = {
  theme: PropTypes.string,
  firstName: PropTypes.string
}

export default withTheme(UserActions)
