import React, { PureComponent } from 'react'
import { bool, node, oneOf, string } from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'

export class FieldQuestion extends PureComponent {
  render () {
    const { htmlFor, children } = this.props
    return (
      <div className="FieldQuestion">
        <label htmlFor={htmlFor}>{children}</label>
      </div>
    )
  }
}

FieldQuestion.displayName = 'FieldQuestion'

FieldQuestion.propTypes = {
  children: node.isRequired,
  htmlFor: string
}

export class FieldAnswer extends PureComponent {
  render () {
    const { children } = this.props
    return <div className="FieldAnswer">{children}</div>
  }
}

FieldAnswer.displayName = 'FieldAnswer'

FieldAnswer.propTypes = {
  children: node.isRequired
}

export class FieldRequired extends PureComponent {
  render () {
    return (
      <abbr className="FieldRequired" title="This field is required">
        *
      </abbr>
    )
  }
}

export class FieldAssistance extends PureComponent {
  render () {
    const { children } = this.props
    return <div className="FieldAssistance">{children}</div>
  }
}

FieldAssistance.displayName = 'FieldAssistance'

FieldAssistance.propTypes = {
  children: node.isRequired
}

export class FieldFeedback extends PureComponent {
  render () {
    const { children, inverse } = this.props
    return (
      <div
        className={classNames('FieldFeedback', inverse && 'inverse')}
      >
        {children}
      </div>
    )
  }
}

FieldFeedback.displayName = 'FieldFeedback'

FieldFeedback.propTypes = {
  children: node.isRequired,
  inverse: bool
}

export class Field extends PureComponent {
  render () {
    const { children, id, status, template, theme } = this.props
    return (
      <div
        className={classNames(
          'Field',
          theme && `Field-${theme}`,
          status && status,
          template && template
        )}
        id={id}
      >
        {children}
      </div>
    )
  }
}

Field.displayName = 'Field'

Field.propTypes = {
  children: node.isRequired,
  id: string,
  status: oneOf([ 'error', 'notice', 'success', 'warning' ]),
  template: string,
  theme: string
}

export default withTheme(Field)
