import React, { PureComponent } from 'react'
import { string } from 'prop-types'

import CheckFieldTemplate from './CheckFieldTemplate'
import MultiTextFieldTemplate from './MultiTextFieldTemplate'
import TextFieldTemplate from './TextFieldTemplate'

class FieldTemplate extends PureComponent {
  render () {
    const { template } = this.props

    const componentMap = {
      checkbox: () => <CheckFieldTemplate {...this.props} />,
      inlineCheckbox: () => <CheckFieldTemplate {...this.props} />,
      address: () => <MultiTextFieldTemplate {...this.props} />
    }

    return componentMap[template]
      ? componentMap[template]()
      : <TextFieldTemplate {...this.props} />
  }
}

FieldTemplate.displayName = 'FieldTemplate'

FieldTemplate.defaultProps = {
  template: 'text'
}

FieldTemplate.propTypes = {
  template: string
}

export default FieldTemplate
