import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '../Primitive/Button'

class LoginSubscribe extends PureComponent {
  render () {
    const { loginOnClick, subscribeOnClick, classes, block } = this.props
    return (
      <Fragment>
        <Button onClick={subscribeOnClick} secondary block={block} className={classes}>Subscribe</Button>
        <Button onClick={loginOnClick} login block={block} className={classes}>Log in</Button>
      </Fragment>
    )
  }
}

LoginSubscribe.propTypes = {
  loginOnClick: PropTypes.func,
  subscribeOnClick: PropTypes.func,
  classes: PropTypes.string,
  block: PropTypes.bool
}

export default LoginSubscribe
