import 'whatwg-fetch'
const qs = require('querystring')

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    const loginRedirectEl = document.querySelector('.js-identity-redirect')
    if (!loginRedirectEl) return
    const redirectUrl = loginRedirectEl.dataset.redirectUrl

    fetch('/auth/login', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      body: JSON.stringify(qs.parse(window.location.hash.replace('#', '')))
    })
      .then(response => {
        return new Promise((resolve, reject) => {
          if (response) {
            response.json()
              .then(body => resolve({ response, body }))
              .catch(reject)
          }
        })
      })
      .then(response => {
        if (response.body.success) {
          window.location = redirectUrl
        }
      })
      .catch(e => {
        throw e
      })
  })
}
