import React from 'react'
import PropTypes from 'prop-types'
import TextControl from './TextControl'
import SelectControl from './SelectControl'
import CheckControl from './CheckControl'
import DateControl from './DateControl'
import AddressControl from './AddressControl'

const Control = (props) => {
  const { type } = props
  const componentMap = {
    address: () => <AddressControl {...props} />,
    checkbox: () => <CheckControl {...props} />,
    inlineCheckbox: () => <CheckControl {...props} />,
    datePicker: () => <DateControl {...props} />,
    radio: () => <CheckControl {...props} />,
    select: () => <SelectControl {...props} />,
    textarea: () => <TextControl multiline {...props} />
  }

  return componentMap[type]
    ? componentMap[type]()
    : <TextControl {...props} />
}

Control.propTypes = {
  type: PropTypes.string.isRequired
}

export default Control
