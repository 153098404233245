import React, { PureComponent } from 'react'
import { bool, string } from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'

import Icon from '../Icon'
import SmartLink from '../SmartLink'

export class IconButton extends PureComponent {
  render () {
    const {
      a11yText,
      className,
      icon,
      rounded,
      small,
      text,
      theme,
      transparent,
      ...other
    } = this.props

    return (
      <SmartLink
        className={classNames(
          'IconButton',
          theme && `IconButton-${theme}`,
          rounded && 'rounded',
          small && 'small',
          transparent && 'transparent',
          className
        )}
        {...other}
      >
        <div className="IconButtonInner">
          <Icon type={icon} a11yText={a11yText} />
          {text && <span className="IconButtonText">{text}</span>}
        </div>
      </SmartLink>
    )
  }
}

IconButton.displayName = 'IconButton'

IconButton.propTypes = {
  a11yText: string,
  className: string,
  icon: string.isRequired,
  rounded: bool,
  small: bool,
  text: string,
  theme: string,
  transparent: bool
}

export default withTheme(IconButton)
