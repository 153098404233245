import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

export class Form extends PureComponent {
  render () {
    const { children, className } = this.props

    return (
      <form
        {...this.props}
        className={classNames(
          'Form',
          className
        )}>
        {children}
      </form>
    )
  }
}

Form.displayName = 'Form'

Form.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string
}

export default Form
