import React from 'react'
const ClipDecoration = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      className="AdventCalendarItem__Clip-path"
      viewBox="0 0 1 1"
    >
      <defs>
        <clipPath
          id="AdventCalendarItem__path"
          clipPathUnits="objectBoundingBox"
        >
          <path
            d={`M0.971,0.26c0-0.017,0.013-0.03,0.029-0.032V0.172C0.983,0.171,0.971,0.157,0.971,0.14
            c0-0.017,0.013-0.03,0.029-0.032V0H0.892c0,0,0,0.001,0,0.001c0,0.018-0.014,0.032-0.032,0.032c-0.018,0-0.032-0.014-0.032-0.032
            l0,0c0,0,0-0.001,0-0.001H0.772c0,0,0,0.001,0,0.001c0,0.018-0.014,0.032-0.032,0.032c-0.018,0-0.032-0.014-0.032-0.032l0,0
            c0,0,0-0.001,0-0.001H0.652C0.65,0.017,0.637,0.029,0.62,0.029C0.603,0.029,0.59,0.017,0.588,0H0.532
            C0.53,0.017,0.517,0.029,0.5,0.029C0.483,0.029,0.47,0.017,0.468,0H0.412C0.41,0.017,0.397,0.029,0.38,0.029
            C0.363,0.029,0.35,0.017,0.348,0H0.292C0.29,0.017,0.277,0.029,0.26,0.029C0.243,0.029,0.23,0.017,0.228,0H0.172
            C0.171,0.017,0.157,0.029,0.14,0.029C0.123,0.029,0.11,0.017,0.108,0H0v0.108c0,0,0.001,0,0.001,0c0.018,0,0.032,0.014,0.032,0.032
            c0,0,0,0,0,0c0,0.018-0.014,0.032-0.032,0.032c0,0-0.001,0-0.001,0v0.056c0,0,0.001,0,0.001,0c0.018,0,0.032,0.014,0.032,0.032
            c0,0.018-0.014,0.032-0.032,0.032c0,0-0.001,0-0.001,0v0.056C0.017,0.35,0.029,0.363,0.029,0.38c0,0.017-0.013,0.03-0.029,0.032
            v0.057C0.017,0.47,0.029,0.483,0.029,0.5c0,0.017-0.013,0.03-0.029,0.032v0.057C0.017,0.589,0.029,0.603,0.029,0.62
            c0,0.017-0.013,0.03-0.029,0.032v0.057C0.017,0.709,0.029,0.723,0.029,0.74c0,0.017-0.013,0.03-0.029,0.032v0.057
            C0.017,0.829,0.029,0.843,0.029,0.86c0,0.017-0.013,0.03-0.029,0.032V1h0.108c0,0,0-0.001,0-0.001c0-0.018,0.014-0.032,0.032-0.032
            s0.032,0.014,0.032,0.032c0,0,0,0.001,0,0.001h0.056c0,0,0-0.001,0-0.001c0-0.018,0.015-0.032,0.032-0.032
            c0.017,0,0.031,0.014,0.032,0.032c0,0,0,0.001,0,0.001h0.056C0.35,0.982,0.365,0.969,0.383,0.971C0.398,0.972,0.411,0.984,0.412,1
            h0.057C0.47,0.982,0.485,0.969,0.502,0.971C0.518,0.972,0.53,0.984,0.532,1h0.057C0.59,0.982,0.605,0.969,0.622,0.971
            C0.638,0.972,0.65,0.984,0.652,1h0.057C0.71,0.982,0.725,0.969,0.742,0.971C0.758,0.972,0.77,0.984,0.772,1h0.057
            C0.83,0.982,0.845,0.969,0.862,0.971C0.878,0.972,0.89,0.984,0.892,1H1V0.892c0,0-0.001,0-0.001,0c-0.018,0-0.032-0.014-0.032-0.032
            c0,0,0,0,0,0c0-0.018,0.014-0.032,0.032-0.032c0,0,0,0,0,0c0,0,0.001,0,0.001,0V0.772c0,0-0.001,0-0.001,0
            c-0.018,0-0.032-0.014-0.032-0.032l0,0c0-0.018,0.014-0.032,0.032-0.032c0,0,0,0,0,0c0,0,0.001,0,0.001,0V0.652
            C0.983,0.65,0.971,0.637,0.971,0.62c0-0.017,0.013-0.03,0.029-0.032V0.532C0.983,0.53,0.971,0.517,0.971,0.5
            c0-0.017,0.013-0.03,0.029-0.032V0.412C0.983,0.41,0.971,0.397,0.971,0.38c0-0.017,0.013-0.03,0.029-0.032V0.292
            C0.983,0.291,0.971,0.277,0.971,0.26z`}
          />
        </clipPath>
      </defs>
    </svg>
  )
}
export default ClipDecoration
