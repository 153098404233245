import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import Content from '../Content'
import Button from '../Button'
import Modal from '../Modal'
import MastheadSelector from '../../MastheadSelector'

class TextWithImageCtaRewards extends React.Component {
  constructor () {
    super()
    this.openModal = this.openModal.bind(this)
    this.state = {
      modalIsOpen: false
    }
  }
  openModal () {
    const currentState = this.state.modalIsOpen
    this.setState({ modalIsOpen: !currentState })
  }
  render () {
    const { theme, packshot, logo, name, subscribeUrl } = this.props
    return (
      <div className="js-subscriber-benefits" data-props={JSON.stringify({ ...this.props })} id="subscribe">
        <div className={classNames(
          'TextWithImageCta',
          theme && `TextWithImageCta-${theme}`,
          name && `subscribed`
        )}>
          { logo ? (
            <Content center gutter width="medium">
              <h2 className="TextWithImageCtaHeading">
                Get all the benefits of membership<br className="is-hidden-mobile" /> when you sign up with
                <img
                  className={classNames(
                    'TextWithImageCtaLogo',
                    name.split(' ').join('-').toLowerCase()
                  )}
                  src={logo}
                  alt={name}
                />
              </h2>
            </Content>
          ) : (
            <Content center gutter width="small">
              <h2 className="TextWithImageCtaHeading">Not a member?<br className="is-hidden-desktop" /> Subscribe today.</h2>
              <h3 className="TextWithImageCtaSubTitle">Members are those with an eligible subscription to one of the newspapers listed above. Our members get more of the things that matter to them.</h3>
            </Content>
          )}
          <Content center gutter>
            <img src={packshot || '/assets/img/content/packshot.png'} className="TextWithImageCtaImage" alt="Subscriber Newspaper packshot" />
          </Content>
          { name ? (
            <Content center gutter width="medium">
              <Fragment>
                <h2 className={classNames('TextWithImageCtaHeading', !name && 'TextWithImageCtaWhite')}>Members get more of the<br className="is-hidden-mobile" /> things that matter to them</h2>
                <p className={classNames('TextWithImageCtaSubTitle', !name && 'TextWithImageCtaWhite')}>From 24/7 access to exclusive stories and expert commentary, to<br className="is-hidden-mobile" /> exclusive giveaways and offers with {name}.</p>
              </Fragment>
            </Content>
          ) : (
            <Fragment>
              <Content center gutter width="wide">
                <h2 className="TextWithImageCtaHeading TextWithImageCtaWhite">More news. More access. More rewards.</h2>
              </Content>
              <Content center gutter width="medium">
                <p className="TextWithImageCtaSubTitle TextWithImageCtaWhite">From 24/7 access to exclusive stories and expert commentary, to exclusive giveaways and offers with +Rewards. Why not subscribe today?</p>
              </Content>
            </Fragment>
          )}
          <Content center gutter>
            {subscribeUrl ? (
              <Button alternativeInverted={!name} alternative={name} blockMobile className="TextWithImageCtaBtn" href={subscribeUrl}>Subscribe</Button>
            ) : (
              <Button alternativeInverted={!name} alternative={name} blockMobile className="TextWithImageCtaBtn" onClick={this.openModal}>Subscribe</Button>
            )}
          </Content>
          {this.state.modalIsOpen &&
            <Modal handleClose={this.openModal} theme={this.theme}>
              <h3 className="ModalHeading">Select a newspaper and sign up today:</h3>
              <MastheadSelector isList {...this.props} />
            </Modal>
          }
        </div>
      </div>
    )
  }
}

TextWithImageCtaRewards.propTypes = {
  theme: PropTypes.string,
  name: PropTypes.string,
  packshot: PropTypes.string,
  logo: PropTypes.string,
  subscribeUrl: PropTypes.string
}

export default TextWithImageCtaRewards
