import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import Notification from '../../../../layout/component/Primitive/Notification'
import Prose from '../../../../layout/component/Primitive/Prose'

const PurchaseForm = (props) => {
  const { authKey, paymentSuccess, paymentFailed, children } = props

  const src = `https://www.bpoint.com.au/webapi/v2/txns/iframe/${authKey}`
  return (
    <Fragment>
      {paymentFailed &&
        <Notification status="error">
          <Prose>
            <p>There was an error in processing your payment. Please try again or alternatively try using a different card.</p>
          </Prose>
        </Notification>
      }
      {!paymentSuccess && <iframe src={src} frameBorder="0" height="550" />}
      {paymentSuccess && children}
    </Fragment>
  )
}

PurchaseForm.propTypes = {
  authKey: PropTypes.string,
  children: PropTypes.node,
  paymentSuccess: PropTypes.bool,
  paymentFailed: PropTypes.bool
}

export default PurchaseForm
