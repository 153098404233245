const handleException = e => {
  window.Raven.context({
    extra: e.extra
  }, () => { throw e })
}

export default async (base, data, sortType) => {
  try {
    const sections = encodeURIComponent(data.sections)
    const sort = sortType ? `&sort=${encodeURIComponent(sortType)}` : ''

    const apiUrl = `${base}api/sortable-offers?sections=${sections}${sort}`

    const request = await fetch(apiUrl, { credentials: 'include' })
    const result = await request.json()

    if (!request.ok) {
      const error = new Error(`${request.status}: ${request.statusText}`)
      error.extra = {
        result,
        headers: request.headers
      }
      throw error
    }

    return result
  } catch (e) {
    handleException(e)
  }
}
