import React from 'react'
import ReactDOM from 'react-dom'
import getOffers from './offers-getter'
import CompetitionWinnersComponent from './CompetitionWinnersComponent'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'

import 'whatwg-fetch'
const debug = require('debug')('competition-winners')

export default (serviceLocator) => {
  const initialise = async (appNode, sortType) => {
    const data = { ...appNode.dataset }
    data.totalPer = Number.parseInt(appNode.dataset.totalPer)
    const result = await getOffers(serviceLocator.instancePath, 0, data.totalPer)
    const theme = document.querySelector('body').dataset.theme
    const hasMore = result.offers.length < result.total

    ReactDOM.render(
      <ThemeContext.Provider value={theme}>
        <CompetitionWinnersComponent
          base={serviceLocator.instancePath}
          hasMore={hasMore}
          originalOffers={result.offers}
          {...data}
        />
      </ThemeContext.Provider>,
      appNode
    )
  }

  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-competition-winners').forEach(initialise)
  })
}
