import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import Prose from '../Primitive/Prose'
import Button from '../Primitive/Button'

class NotEligibleButtonArea extends PureComponent {
  render () {
    const { subscribeOnClick, block } = this.props
    return (
      <Fragment>
        <Button block={block} className="RedemptionButtonCTA" primary uppercase disabled>Not Available</Button>
        <Prose className="RedemptionButtonFeedback">
          <p>Sorry, you're not eligible to redeem this offer.</p>
          <p>Your registered account or subscription does not include access to redeem offers.</p>
          <p>To subscribe or upgrade your subscription to one which includes offer redemption, give us a call on 1300 MY NEWS (1300 696 397), or <a href="#" onClick={subscribeOnClick}>sign up online</a> today.</p>
        </Prose>
      </Fragment>
    )
  }
}

NotEligibleButtonArea.propTypes = {
  subscribeOnClick: PropTypes.func,
  block: PropTypes.bool
}

export default NotEligibleButtonArea
