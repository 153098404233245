import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Button from '../Button'

class LoadMore extends PureComponent {
  render () {
    const { clicked } = this.props

    return (
      <div className="LoadMore">
        <Button alternative onClick={clicked}>Load More</Button>
      </div>
    )
  }
}

LoadMore.propTypes = {
  clicked: PropTypes.func
}

export default LoadMore
