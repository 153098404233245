import React from 'react'
import { bool, func, oneOf, string } from 'prop-types'

const NativeCheckControl = props => {
  const {
    checked,
    className,
    disabled,
    id,
    name,
    onChange,
    required,
    type,
    value
  } = props

  return (
    <input
      checked={checked}
      className={className}
      disabled={disabled}
      id={id}
      name={name}
      onChange={onChange}
      required={required}
      type={type}
      readOnly={!onChange}
      value={value}
    />
  )
}

NativeCheckControl.displayName = 'NativeCheckControl'

NativeCheckControl.propTypes = {
  checked: bool,
  className: string,
  disabled: bool,
  id: string,
  name: string.isRequired,
  onChange: func,
  required: bool,
  type: oneOf([ 'checkbox', 'radio' ]).isRequired,
  value: string.isRequired
}

export default NativeCheckControl
