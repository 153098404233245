import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../Primitive/LoadingSpinner'
import FormBuilderQuestion from './FormBuilderQuestion'
import Content from '../Primitive/Content'
import Prose from '../Primitive/Prose'
import { Grid, GridItem } from '../Primitive/Grid'
import Notification from '../Primitive/Notification'
import Button from '../Primitive/Button'

class FormBuilder extends PureComponent {
  render () {
    const { onSubmit, onChange, data = {}, error = {}, waiting, submissionSuccess, errorMessage, form, PostFormComponent, config = {}, children, hasGutter } = this.props
    const { questions, ctaText, description } = form || {}

    const widthClasses = {
      'full': [ 'one-whole' ],
      'half': [ 'tablet-one-half' ],
      'quarter': [ 'tablet-one half', 'desktop-one-quarter' ]
    }

    return (
      <Content center gutter={hasGutter} className="FormBuilder">
        {submissionSuccess
          ? children
          : <form className="form" action="/api/form-builder/" method="post" onSubmit={onSubmit}>
            {description &&
              <Prose html={description} />
            }
            {error && Object.keys(error).length > 0 &&
              <Notification status="error">
                <p>We were not able to process your request. Please check below and try again.</p>
                {errorMessage && <p>{errorMessage}</p>}
              </Notification>
            }
            <Grid>
              {questions && questions.map((question, i) => {
                return (
                  <GridItem key={`form-builder-${i}`} sizes={widthClasses[question.width]}>
                    <FormBuilderQuestion
                      question={question}
                      error={error}
                      data={data}
                      config={config}
                      id={i}
                      onChange={onChange}
                    />
                  </GridItem>
                )
              })}
            </Grid>
            {PostFormComponent && <PostFormComponent />}
            {waiting ? <LoadingSpinner />
              : (
                <Button primary type="submit">{ctaText || 'Submit'}</Button>
              )
            }
          </form>
        }
      </Content>
    )
  }
}

FormBuilder.propTypes = {
  onSubmit: PropTypes.func,
  onChange: PropTypes.func,
  data: PropTypes.object,
  config: PropTypes.object,
  waiting: PropTypes.bool,
  submissionSuccess: PropTypes.bool,
  children: PropTypes.element,
  PostFormComponent: PropTypes.func,
  errorMessage: PropTypes.string,
  error: PropTypes.object,
  form: PropTypes.object,
  hasGutter: PropTypes.bool
}

export default FormBuilder
