
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import Content from '../Primitive/Content'
import getEmphasisedText from '../Primitive/SummaryWrapper/lib/emphasised-text-determiner'

class Title extends PureComponent {
  render () {
    const { theme, title, subtitle, emphasisedWordLocation, size, search, spacing, leftAligned, richTextTitle, gutterless, regularFontWeight, titleStyle } = this.props
    const { emphasisedText, unemphasisedText } = getEmphasisedText(title, emphasisedWordLocation)
    return (
      <div className={classNames(
        'Title',
        theme && `Title-${theme}`,
        size === 'Large' && 'TitleLarge',
        size === 'ExtraLarge' && 'TitleExtraLarge',
        size === 'Small' && 'TitleSmall',
        search && 'TitleSearch',
        spacing === 'None' && 'TitleNoSpacing',
        spacing === 'Small' && 'TitleSmallSpacing',
        spacing === 'Medium' && 'TitleSpacing',
        spacing === 'Large' && 'TitleLargeSpacing',
        leftAligned && 'TitleLeftAligned',
        regularFontWeight && 'TitleRegular',
        titleStyle && `Title--${titleStyle}`
      )}>
        <Content
          center
          width={theme === 'aus' ? 'small' : undefined}
          gutter={!gutterless}
        >
          { title && !richTextTitle &&
            <h2 className="TitleHeading">
              {emphasisedWordLocation === 'Last' && unemphasisedText + ' '}
              {emphasisedText && <span className="TitleEmphasised">{emphasisedText}</span>}
              {emphasisedWordLocation === 'First' && ' ' + unemphasisedText}
              {!emphasisedWordLocation && unemphasisedText}
            </h2>
          }

          { title && richTextTitle &&
            <h2 className="TitleHeading" dangerouslySetInnerHTML={{ __html: title }} />
          }

          { subtitle &&
            <div
              className="TitleSubHeading"
              dangerouslySetInnerHTML={{ __html: subtitle }}
            />
          }
        </Content>
      </div>
    )
  }
}

Title.propTypes = {
  theme: PropTypes.string,
  title: PropTypes.string,
  subtitle: PropTypes.string,
  emphasisedWordLocation: PropTypes.string,
  size: PropTypes.string,
  search: PropTypes.bool,
  leftAligned: PropTypes.bool,
  spacing: PropTypes.string,
  richTextTitle: PropTypes.bool,
  gutterless: PropTypes.bool,
  regularFontWeight: PropTypes.bool,
  titleStyle: PropTypes.oneOf([ 'alternative' ])
}

export default withTheme(Title)
