import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../Theme/withTheme'
import Carousel from '../Primitive/Carousel'
class OfferSeasonalHero extends PureComponent {
  render () {
    const { theme, images, offerTitle, isRehydrated } = this.props

    return (
      <div className={classNames(
        'OfferSeasonalHero',
        theme && `OfferSeasonalHero-${theme}`,
        'js-offer-hero-snow-wrapper'
      )}>
        <div className="OfferSeasonalHeroBg OfferSeasonalHeroBg--reversed">
          <img className="OfferSeasonalHeroBgBaubles" src="/assets/img/content/seasonal/baubles.png" alt="" />
          <picture>
            <source media="(min-width: 1500px)" srcSet="/assets/img/content/seasonal/offer-seasonal-hero--desktop-wide.webp" type="image/webp" />
            <source media="(min-width: 1500px)" srcSet="/assets/img/content/seasonal/offer-seasonal-hero--desktop-wide.png" type="image/png" />
            <source media="(min-width: 550px)" srcSet="/assets/img/content/seasonal/offer-seasonal-hero--desktop.webp" type="image/webp" />
            <source media="(min-width: 550px)" srcSet="/assets/img/content/seasonal/offer-seasonal-hero--desktop.png" type="image/png" />
            <source srcSet="/assets/img/content/seasonal/offer-seasonal-hero--mobile.webp" type="image/webp" />
            <source srcSet="/assets/img/content/seasonal/offer-seasonal-hero--mobile.png" type="image/png" />
            <img src="/assets/img/content/seasonal/offer-seasonal-hero--mobile.png" alt="" />
          </picture>
        </div>
        <div className="OfferSeasonalHero__Image">
          {images.length === 1 ? (
            <img src={images[0].url} alt={offerTitle} />
          ) : (
            <Carousel autoplay>
              {images.map((image, i) => (
                <img className={!isRehydrated ? 'is-hidden' : ''} key={`image${i}`} src={image.url} alt={offerTitle} />
              ))}
            </Carousel>
          )}
        </div>
        <div className="OfferSeasonalHeroBg">
          <img className="OfferSeasonalHeroBgBaubles" src="/assets/img/content/seasonal/baubles.png" alt="" />
          <picture>
            <source media="(min-width: 1500px)" srcSet="/assets/img/content/seasonal/offer-seasonal-hero--desktop-wide.webp" type="image/webp" />
            <source media="(min-width: 1500px)" srcSet="/assets/img/content/seasonal/offer-seasonal-hero--desktop-wide.png" type="image/png" />
            <source media="(min-width: 550px)" srcSet="/assets/img/content/seasonal/offer-seasonal-hero--desktop.webp" type="image/webp" />
            <source media="(min-width: 550px)" srcSet="/assets/img/content/seasonal/offer-seasonal-hero--desktop.png" type="image/png" />
            <source srcSet="/assets/img/content/seasonal/offer-seasonal-hero--mobile.webp" type="image/webp" />
            <source srcSet="/assets/img/content/seasonal/offer-seasonal-hero--mobile.png" type="image/png" />
            <img src="/assets/img/content/seasonal/offer-seasonal-hero--mobile.png" alt="" />
          </picture>
        </div>
        <canvas className="OfferSeasonalHeroBg__snow js-offer-hero-snow" />
      </div>
    )
  }
}

OfferSeasonalHero.propTypes = {
  theme: PropTypes.string,
  images: PropTypes.arrayOf(PropTypes.object),
  offerTitle: PropTypes.string,
  isRehydrated: PropTypes.bool
}

export default withTheme(OfferSeasonalHero)
