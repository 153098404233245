import React, { Fragment, PureComponent } from 'react'
import PropTypes from 'prop-types'
import withTheme from '../../Theme/withTheme'

const timeRemaining = (date) => (date ? new Date(date) - new Date() : 0)
const hoursToMs = (hours) => hours * 60 * 60 * 1000
const secondsToMs = (seconds) => seconds * 1000
class Countdown extends PureComponent {
  constructor (props) {
    super(props)
    this.countdownInterval = null
    const { openingDate } = this.props
    // Set initial state
    this.state = {
      difference: timeRemaining(openingDate),
      days: 0,
      hours: 0,
      minutes: 0,
      seconds: 0,
      showCountdown: false
    }
  }

  calculateTimeLeft () {
    const { openingDate, onCountdownEnd } = this.props
    const difference = timeRemaining(openingDate)
    if (difference > 0) {
      this.setState({
        difference: difference,
        days: Math.floor(this.state.difference / (1000 * 60 * 60 * 24)),
        hours: Math.floor((this.state.difference / (1000 * 60 * 60)) % 24),
        minutes: Math.floor((this.state.difference / 1000 / 60) % 60),
        seconds: Math.floor((this.state.difference / 1000) % 60),
        showCountdown: true
      })
    } else {
      this.setState({ showCountdown: false })
      onCountdownEnd && onCountdownEnd()
    }
  }

  componentWillMount () {
    this.calculateTimeLeft()
  }

  componentDidMount () {
    this.countdownInterval = setInterval(() => {
      this.calculateTimeLeft()
    }, 1000)
  }

  componentWillUnmount () {
    if (this.countdownInterval) clearInterval(this.countdownInterval)
  }

  render () {
    const { difference, days, hours, minutes, seconds, showCountdown } =
      this.state
    const { theme } = this.props
    const countdownClass = `Countdown-${theme}`
    return (
      <Fragment>
        {showCountdown && (
          <Fragment>
            {difference > hoursToMs(24) - secondsToMs(1) && (
              <Fragment>
                <div className={countdownClass}>
                  <span className="CountdownNumber">{days}</span>
                  <span>Days</span>
                </div>
                <div className={countdownClass}>
                  <span className="CountdownNumber">
                    {hours < 10 ? (!hours ? '00' : '0' + hours) : hours}
                  </span>
                  <span>Hours</span>
                </div>
                <div className={countdownClass}>
                  <span className="CountdownNumber">
                    {!minutes ? '00' : minutes < 10 ? '0' + minutes : minutes}
                  </span>
                  <span>Mins</span>
                </div>
              </Fragment>
            )}
            {difference < hoursToMs(24) - secondsToMs(1) && difference > secondsToMs(59) && (
              <Fragment>
                <div className={countdownClass}>
                  <span className="CountdownNumber">
                    {hours < 10 ? (!hours ? '00' : '0' + hours) : hours}
                  </span>
                  <span>Hours</span>
                </div>
                <div className={countdownClass}>
                  <span className="CountdownNumber">
                    {!minutes ? '00' : minutes < 10 ? '0' + minutes : minutes}
                  </span>
                  <span>Mins</span>
                </div>
                <div className={countdownClass}>
                  <span className="CountdownNumber">
                    {!seconds ? '00' : seconds < 10 ? '0' + seconds : seconds}
                  </span>
                  <span>Secs</span>
                </div>
              </Fragment>
            )}
            {difference < secondsToMs(59) && (
              <div className={countdownClass}>
                <span className="CountdownNumber">
                  {!seconds ? '00' : seconds < 10 ? '0' + seconds : seconds}
                </span>
                <span>Secs</span>
              </div>
            )}
          </Fragment>
        )}
      </Fragment>
    )
  }
}

Countdown.propTypes = {
  openingDate: PropTypes.string,
  onCountdownEnd: PropTypes.func,
  theme: PropTypes.string
}

export default withTheme(Countdown)
