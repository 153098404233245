import React from 'react'
import ReactDOM from 'react-dom'
import ThemeContext from '../../../site/layout/component/Theme/ThemeContext'
import KeyFactsContainer from './KeyFacts'

export default () => {
  document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.js-key-facts').forEach(appNode => {
      const theme = document.querySelector('body').dataset.theme
      const props = JSON.parse(appNode.dataset.props)

      ReactDOM.render(
        <ThemeContext.Provider value={theme}>
          <KeyFactsContainer {...props} />
        </ThemeContext.Provider>,
        appNode
      )
    })
  })
}
