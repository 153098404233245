import React, { PureComponent } from 'react'
import classNames from 'classnames'
import PropTypes from 'prop-types'

class LoadingSpinnerRewards extends PureComponent {
  render () {
    const { large } = this.props
    return (
      <div className={classNames(
        'Spinner',
        large && 'SpinnerLarge'
      )}>
        <div className="SpinnerInner">
          {[ ...Array(4).keys() ].map(i =>
            <div key={i} />
          )}
        </div>
      </div>
    )
  }
}

LoadingSpinnerRewards.propTypes = {
  large: PropTypes.bool
}

export default LoadingSpinnerRewards
