import React, { PureComponent } from 'react'
import ThemeContext from './ThemeContext'

const withTheme = ChildComponent => {
  class WithTheme extends PureComponent {
    render () {
      return (
        <ThemeContext.Consumer>
          {theme => <ChildComponent {...this.props} theme={theme} />}
        </ThemeContext.Consumer>
      )
    }
  }

  WithTheme.displayName = `${ChildComponent.displayName || ChildComponent.name}`

  return WithTheme
}

export default withTheme
