import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import LoadingSpinner from '../../../../layout/component/Primitive/LoadingSpinner'
import Button from '../../../../layout/component/Primitive/Button'

const BasicRedeemButton = (props) => {
  const { ctaText, children, onButtonClick, waiting, buttonClicked = false } = props

  if (waiting) return <LoadingSpinner />

  return (
    <Fragment>
      {!buttonClicked && <Button uppercase target="_blank" onClick={onButtonClick} primary>{ctaText}</Button>}
      {buttonClicked && children}
    </Fragment>
  )
}

BasicRedeemButton.propTypes = {
  ctaText: PropTypes.string,
  children: PropTypes.element,
  buttonClicked: PropTypes.bool,
  waiting: PropTypes.bool,
  onButtonClick: PropTypes.func
}

export default BasicRedeemButton
