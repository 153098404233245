import React, { PureComponent, Fragment } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import withTheme from '../../Theme/withTheme'
import { Grid, GridItem } from '../Grid'
import Countdown from '../Countdown'
import ReactMounter from '../ReactMounter'

class SummaryAus extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      countdownEnded: false
    }
  }

  render () {
    const {
      displayShortHeadline,
      shortHeadline,
      image,
      width,
      category,
      status,
      __fullUrlPath,
      split,
      showSell,
      sell,
      showExtraInfo,
      extraInfo,
      extraInfoLink,
      FeaturedSummary,
      isRedemptionHistory,
      showCountdownOverlay,
      additionalOverlayClass,
      openingDate
    } = this.props
    const endCountdown = () => this.setState({ countdownEnded: true })
    const shouldShowCountdown =
      status === 'Coming soon' &&
      !this.state.countdownEnded &&
      showCountdownOverlay
    const Wrapper = __fullUrlPath ? 'a' : 'div'
    const ExtraInfo = () =>
      extraInfoLink ? (
        <p className="SummaryLink">
          <a
            dangerouslySetInnerHTML={{ __html: extraInfo }}
            href={extraInfoLink}
          />
        </p>
      ) : (
        <p
          className="SummaryLink"
          dangerouslySetInnerHTML={{ __html: extraInfo }}
        />
      )

    const gridSizes = {
      thirds: [ [ 'tablet-one-third' ], [ 'tablet-two-thirds' ] ],
      'five-ninths': [
        [ 'tablet-landscape-five-ninths' ],
        [ 'tablet-landscape-four-ninths' ]
      ]
    }

    return (
      <Wrapper
        href={__fullUrlPath}
        className={classNames(
          'Summary-aus',
          FeaturedSummary && 'SummaryFeatured',
          split && 'Summary-split',
          isRedemptionHistory && 'Summary-history'
        )}
      >
        {split ? (
          <Fragment>
            {status &&
              ![ 'Available', 'Sold out', 'Closed' ].includes(status) && (
              <span className="SummaryLastChance">{status}</span>
            )}
            <Grid vcentered={split === 'five-ninths'} gutterless>
              <GridItem sizes={gridSizes[split][0]}>
                <div className="SummaryImage">
                  <img src={image.url} alt={shortHeadline} />
                  {[ 'Sold out', 'Closed' ].includes(status) && (
                    <div className="SummaryOverlay">
                      <span>{status}</span>
                    </div>
                  )}
                  {shouldShowCountdown ? (
                    <ReactMounter
                      className={classNames(
                        'SummaryOverlay',
                        'SummaryCountdown',
                        'js-countdown',
                        additionalOverlayClass
                      )}
                      clientSideProps={{ openingDate, endCountdown }}
                    >
                      <Countdown
                        openingDate={openingDate}
                        onCountdownEnd={endCountdown}
                      />
                    </ReactMounter>
                  ) : null}
                </div>
              </GridItem>
              <GridItem sizes={gridSizes[split][1]}>
                <div
                  className={classNames(
                    'SummaryInner',
                    'SummaryInnerHorizontal',
                    width === 'One' && 'SummaryVerticalAlign'
                  )}
                >
                  {category && (
                    <span className="SummaryCategory">{category}</span>
                  )}
                  <h2
                    className={classNames(
                      'SummaryHeadline',
                      width === 'One' && 'SummaryHeadlineLarge',
                      width === 'Two' && 'SummaryHeadlineSmall'
                    )}
                    dangerouslySetInnerHTML={{ __html: displayShortHeadline }}
                  />
                  {(showSell || showExtraInfo) && (
                    <div className="SummaryContent">
                      {showSell && (
                        <p dangerouslySetInnerHTML={{ __html: sell }} />
                      )}
                      {showExtraInfo && <ExtraInfo />}
                    </div>
                  )}
                </div>
              </GridItem>
            </Grid>
          </Fragment>
        ) : (
          <Fragment>
            <div className="SummaryImage">
              <img src={image.url} alt={shortHeadline} />
              {[ 'Sold out', 'Closed' ].includes(status) && (
                <div className="SummaryOverlay">
                  <span>{status}</span>
                </div>
              )}
              {shouldShowCountdown ? (
                <ReactMounter
                  className={classNames(
                    'SummaryOverlay',
                    'SummaryCountdown',
                    'js-countdown',
                    additionalOverlayClass
                  )}
                  clientSideProps={{ openingDate, endCountdown }}
                >
                  <Countdown
                    openingDate={openingDate}
                    onCountdownEnd={endCountdown}
                  />
                </ReactMounter>
              ) : null}
            </div>
            <div className="SummaryInner">
              {status &&
                ![ 'Available', 'Sold out', 'Closed' ].includes(status) && (
                <span className="SummaryLastChance">{status}</span>
              )}
              <span className="SummaryCategory">{category}</span>
              <h2
                className="SummaryHeadline"
                dangerouslySetInnerHTML={{ __html: displayShortHeadline }}
              />
            </div>
          </Fragment>
        )}
      </Wrapper>
    )
  }
}

SummaryAus.propTypes = {
  shortHeadline: PropTypes.string,
  displayShortHeadline: PropTypes.string,
  image: PropTypes.object,
  __fullUrlPath: PropTypes.string,
  split: PropTypes.string,
  status: PropTypes.string,
  width: PropTypes.string,
  category: PropTypes.string,
  extraInfo: PropTypes.string,
  extraInfoLink: PropTypes.string,
  sell: PropTypes.string,
  showSell: PropTypes.bool,
  showExtraInfo: PropTypes.bool,
  FeaturedSummary: PropTypes.bool,
  isRedemptionHistory: PropTypes.bool,
  showCountdownOverlay: PropTypes.bool,
  additionalOverlayClass: PropTypes.string,
  openingDate: PropTypes.string
}

export default withTheme(SummaryAus)
