import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import withTheme from '../component/Theme/withTheme'

class NavigationRewards extends PureComponent {
  componentDidMount () {
    const overlayExists = document.querySelector('.NavigationWidgetWrapper-overlay')
    if (overlayExists) {
      document.body.classList.add('navigation-has-overlay')
    }
  }
  render () {
    const { theme, items, currentSection, instancePath, seasonalCampaignEnabled } = this.props
    const path = `${instancePath.slice(0, -1)}${currentSection}`
    return (
      <div className={classNames(
        'Navigation',
        theme && `Navigation-${theme}`
      )}>
        <div className="NavigationItems">
          {items && items.map((item, i) => (
            <a
              key={i}
              className={classNames(
                'NavigationItem',
                seasonalCampaignEnabled && 'NavigationItem--compact',
                path === item.url && 'active'
              )}
              href={item.url}
            >
              <span className="is-hidden-mobile">{item.text}</span>
              <span className="is-hidden-tablet">{item.shortText || item.text}</span>
            </a>
          ))}
          {seasonalCampaignEnabled &&
          <a
            className={classNames(
              'NavigationItem',
              seasonalCampaignEnabled && 'NavigationItem--compact',
              path === 'advent-calendar' && 'active'
            )}
            href={`${instancePath}advent-calendar`}
          >
            <span className="is-hidden-mobile">Advent Calendar</span>
            <span className="is-hidden-tablet">Advent</span>
          </a>
          }
        </div>
      </div>
    )
  }
}

NavigationRewards.propTypes = {
  theme: PropTypes.string,
  currentSection: PropTypes.string,
  instancePath: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.object),
  seasonalCampaignEnabled: PropTypes.bool
}

export default withTheme(NavigationRewards)
