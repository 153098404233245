import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import withTheme from '../../../component/Theme/withTheme'
import FilterMenuAus from './FilterMenuAus'
import FilterMenuRewards from './FilterMenuRewards'

class FilterMenu extends PureComponent {
  render () {
    const { theme } = this.props
    const Component = {
      'aus': <FilterMenuAus {...this.props} />,
      'rewards': <FilterMenuRewards {...this.props} />
    }

    return Component[theme]
  }
}

FilterMenu.propTypes = {
  theme: PropTypes.string,
  state: PropTypes.object
}

export default withTheme(FilterMenu)
