const debug = require('debug')('takeover')

// Navigate to takeover link when clicking on the background image
export default () => {
  debug('init')
  document.addEventListener('DOMContentLoaded', () => {
    const container = document.getElementById('content')
    const takeoverLink = container.dataset.takeoverlink
    container.addEventListener('click', function (e) {
      if (e.target === this) {
        e.preventDefault()
        window.location.href = takeoverLink
      }
    })
  })
}
